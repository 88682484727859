@import "../../assets/theme/variable.scss";

.tourmain {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  align-items: flex-start;
  .trLft {
    min-width: 35%;
    margin-top: 50px;
    position: sticky;
    top: 70px;
    // #simple-tabpanel-upcoming,
    // #simple-tabpanel-history > div > p {
    //   width: 100%;
    //   max-height: 500px;
    //   overflow-y: scroll;
    //   padding-right: 5px;
    // }

    @media (max-width: 991px) {
      position: relative;
      top: unset;
    }
    .css-1gsv261 {
      border: 0;
    }
    button {
      width: 50% !important;
      color: $white !important;
      font-family: "Montserrat", sans-serif;
      border: 2px solid transparent;
      &:not(.Mui-selected) {
        border-bottom: 2px solid #3e3e3e;
      }
      @media (max-width: 767px) {
        font-size: 12px;
        border-radius: 0;
        margin: 0 -1px;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      .MuiTabs-indicator {
        bottom: 5px;
      }
    }
    div#simple-tabpanel-upcoming,
    #simple-tabpanel-history {
      & > div {
        padding: 0 !important;
        padding-top: 20px !important;
      }
    }
  }
  .trRyt {
    width: 60%;
    .trTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 767px) {
        justify-content: center;
        margin: 0 0;
      }

      h4 {
        font-size: 17px;
        font-weight: 400;
        color: $secondary;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .trIcn {
        display: flex;
        @media (max-width: 767px) {
          position: fixed;
          right: 20px;
          bottom: 20px;
          flex-direction: column;
          gap: 10px;
        }
        figure {
          margin-left: 20px;
          cursor: pointer;
          img {
            max-width: 70px;
          }
        }
      }
      .live {
        display: flex;
        align-items: center;
        figure {
          width: 40px;
        }
        h5 {
          font-size: 18px;
          padding-left: 10px;
          color: $pink;
        }
      }
    }
    .sliderdiv {
      // margin-top: 30px;
      // margin-bottom: 80px;
      margin: 30px 30px 80px;
      position: relative;
      cursor: grab;
      .custom-prev-arrow {
        position: absolute;
        top: 50%;
        left: -30px;
        z-index: 1;
        width: 40;
        height: 40;
        transform: translateY(-50%);
        cursor: pointer;
        // background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
      .custom-next-arrow {
        position: absolute;
        top: 50%;
        right: -30px;
        z-index: 1;
        width: 40;
        height: 40;
        transform: translateY(-50%);
        cursor: pointer;
        // background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
      .slick-dots {
        bottom: -45px;
        button {
          font-size: 0 !important;
        }
      }
    }
    .matchteams {
      display: flex;
      justify-content: space-between;
      align-items: center;
      figure.vs {
        width: 20%;
        text-align: center;
        img {
          max-width: 70px;
        }
      }
      .team1 {
        display: flex;
        align-items: center;
        width: 40%;
        figure {
          img {
            max-width: 60px;
          }
        }
        .scrodiv {
          display: flex;
          flex-direction: column;
          h1 {
            color: white;
            text-align: center;
            padding-bottom: 10px;
          }
          h3 {
            color: white;
            font-size: 26px;
            text-align: center;
            padding-bottom: 10px;
            font-family: "Impact";
          }
        }
        h2 {
          font-size: 24px;
          color: $white;
          margin-left: 30px;
          font-weight: 600;
          text-align: center;
        }
      }
      .team2 {
        justify-content: flex-end;
        h2 {
          margin-right: 30px;
          margin-left: 0;
        }
      }
    }
    .btmdiv {
      .matchdetail {
        display: flex;
        margin: auto;
        justify-content: space-between;
        padding: 0px 20px;
        border-bottom: 7px solid #3e3e3e;
        border-radius: 0%;
        margin-top: 20px;
        @media (max-width: 767px) {
          border-bottom: 2px solid #3e3e3e;
          padding: 0 15px;
        }

        .h4div {
          font-size: 17px;
          background: #dadada;
          opacity: 72%;
          padding: 10px 25px;
          border-radius: 6px 6px 0 0;
          cursor: pointer;
          width: 48%;
          box-sizing: border-box;
          text-align: center;
        }
        .h4div2 {
          font-size: 17px;
          background: #dadada;
          opacity: 72%;
          padding: 10px 25px;
          border-radius: 6px 6px 0 0;
          cursor: pointer;
          box-sizing: border-box;
          text-align: center;
          width: 99% !important;
        }
      }
      h4.heading {
        background: #034c3a;
        color: white;
        font-size: 15px;
        font-weight: 500;
        padding: 15px 25px;
        border-radius: 50px;
        border: 1px solid #c8a767;
        margin: 30px 0;
        @media (max-width: 767px) {
          margin: 20px 0;
          padding: 9px 25px;
          font-size: 12px;
        }
      }
      .userlisting {
        padding-top: 10px;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 15px;
          h5 {
            font-size: 16px;
            font-weight: 300;
            color: white;
            @media (max-width: 767px) {
            }
          }
        }
        .users {
          .user {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ffffff1f;
            padding: 15px 0;
            cursor: pointer;
            .userleft {
              display: flex;
              align-items: center;
              figure {
                width: 50px;
                height: 50px;
                img {
                  border-radius: 50%;
                  border: 1px solid #ffffff6b;
                }
              }
              h4 {
                font-size: 16px;
                font-weight: 300;
                color: $white;
                margin-left: 20px;
              }
            }
            h3 {
              color: $secondary;
              font-size: 16px;
              letter-spacing: -0.16px;
              font-weight: 300;
            }
          }
          .newentry {
            font-size: 18px;
            width: 70%;
            padding: 15px;
            font-weight: 900;
            margin: 30px auto 0;
            cursor: pointer;
            background: $secondary;
            color: $primary;
            outline: unset;
            display: block;
            border: 0;
            @media (max-width: 767px) {
              width: 100%;
              margin: 30px 0 10px;
            }
          }
        }
      }
    }
  }
}

.tourmain,
.ldrbrd_wrp {
  .btmdiv {
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            text-align: left;
            font-size: 20px;
            font-weight: 500;
            color: white;
            @media (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
        &.btm_div2 {
          tr {
            th {
              font-size: 18px;
              padding: 20px 0 10px;
              font-weight: 300;
              @media (max-width: 767px) {
                font-size: 12px;
                padding: 15px 7px 10px;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            color: white;
            text-align: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275) !important;
            &.points {
              font-size: 19px;
              letter-spacing: -0.19px;
              font-weight: 700;
              @media (max-width: 767px) {
                font-size: 12px;
                letter-spacing: -0.12px;
              }
            }
            .user {
              display: flex;
              justify-content: flex-start;
              gap: 30px;
              align-items: center;
              text-align: left;
              // border-bottom: 1px solid #ffffff1f;
              padding: 20px 0;
              cursor: pointer;
              @media (max-width: 767px) {
                gap: 10px;
                padding: 15px 0;
              }
              .userleft {
                display: flex;
                align-items: center;
                figure {
                  width: 50px;
                  height: 50px;
                  @media (max-width: 767px) {
                    width: 36px;
                    height: 36px;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                  &.user_dp {
                    img {
                      object-fit: cover;
                      border: 1px solid #ffffff70;
                      border-radius: 50%;
                    }
                  }
                }
                h4 {
                  font-size: 16px;
                  font-weight: 300;
                  color: $white;
                  margin-left: 20px;
                }
              }
              h3 {
                color: $secondary;
                font-size: 16px;
                letter-spacing: -0.16px;
                font-weight: 300;
              }
              &.userleft2 {
                padding: 22px 0;
                h4 {
                  font-size: 19px;
                  letter-spacing: -0.19px;
                  @media (max-width: 767px) {
                    font-size: 12px;
                    letter-spacing: -0.12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: $secondary !important;
  font-size: 10px !important;
}

.slick-dots li button:before {
  color: #444444 !important;
  font-size: 10px !important;
}

.slick-prev,
.slick-next {
  left: -25px;
  display: none !important;
}

@media (max-width: 1200px) {
  .tourmain {
    flex-wrap: wrap;
  }

  .tourmain .trLft {
    width: 50%;
    margin: 50px auto 0;
  }
  .tornmain .sb {
    text-align: center;
  }
  .tourmain .trRyt {
    width: 70%;
    margin: 0 auto;
  }
  .tourmain .trRyt .matchteams .team1 h2 {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .tourmain .trLft {
    width: 70%;
  }
  .tourmain .trRyt {
    width: 90%;
    margin: 0 auto;
  }
  .tourmain .trRyt .btmdiv .matchdetail .h4div {
    font-size: 13px;
    padding: 10px 10px;
  }
  .tourmain .trRyt .btmdiv h4.heading {
    font-size: 13px;
  }
  .tourmain .trRyt .btmdiv .userlisting .head h5 {
    font-size: 14px;
  }
  .tourmain .trRyt .btmdiv .userlisting .users .user .userleft figure {
    width: 40px;
    height: 40px;
  }
  .tourmain .trRyt .btmdiv .userlisting .users .user .userleft h4 {
    font-size: 13px;
    margin-left: 10px;
  }
  .tourmain .trRyt .btmdiv .userlisting .users .user h3 {
    font-size: 13px;
  }
  .tourmain .trRyt .trTop .trIcn figure img {
    max-width: 40px;
  }
}
@media (max-width: 767px) {
  .tourmain .trRyt .btmdiv .userlisting .head h5,
  .tourmain .trRyt .btmdiv .userlisting .users .user .userleft h4 {
    font-size: 12px;
  }
  .tourmain .trRyt .btmdiv .matchdetail .h4div {
    font-size: 12px;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tornmain .sb h2 {
    font-size: 16px;
  }
  .tornmain .bck_btn_trny {
    margin-bottom: -23px;
  }
  .tourmain .trLft {
    width: 100%;
    margin-top: 15px;
  }
  .tourmain .trRyt {
    width: 100%;
    display: none;
    &.added {
      display: block;
      position: fixed;
      height: 100vh;
      overflow: auto;
      background: #303030;
      z-index: 999;
      left: 0;
      top: 0;
      padding: 16px 16px 80px;
    }
  }
  body:has(.tourmain .trRyt.added) {
    overflow: hidden;
  }
  .trLft .mtch_crd_inr .crd_top .crd_lft .crd_mdl h2 {
    font-size: 14px;
  }
  .trLft .mtch_crd_inr .crd_top .crd_lft .crd_mdl p {
    font-size: 10px;
  }
  .trLft .mtch_crd_inr .crd_top .crd_lft figure {
    width: 30px;
  }
  .trLft .mtch_crd_inr .crd_top .mytor {
    width: unset;
    border: 0;
    padding-left: 0px;
  }
  .trLft .mtch_crd_inr .crd_top .mytor p {
    font-size: 10px;
  }
  .trLft .mtch_crd_inr .crd_top .mytor h3 {
    font-size: 21px;
    font-weight: 300;
    white-space: nowrap;
  }
  .trLft .mtch_crd_inr h3 {
    font-size: 16px;
  }
  .trLft .mtch_crd_inr {
    padding: 10px;
  }
  .trLft .mtch_crd_inr .crdbtm2 .detail h4 {
    padding: 5px 10px;
    font-size: 10px;
  }
  .trLft .mtch_crd_inr .crdbtm2 h5 {
    font-size: 12px;
  }
  .tourmain .trRyt .trTop .live h5 {
    font-size: 16px;
  }
  .tourmain .trRyt .trTop .live figure {
    width: 30px;
  }
  .tourmain .trRyt .trTop .live {
    margin-left: 20px;
  }
  .tornmain_right_show {
    .sb,
    .trLft {
      display: none;
    }
    .trRyt {
      display: block;
      .sb {
        display: block;
      }
      .bck_btn {
        position: relative;
        top: 4px;
      }
    }
  }
}

@media (max-width: 600px) {
  .tourmain .trRyt .matchteams .team1 figure img {
    max-width: 50px;
  }
  .tourmain .trRyt .matchteams .team1 h2 {
    font-size: 16px;
  }
  .tourmain .trRyt .matchteams .team1 figure {
    width: 50px;
  }
  .tourmain .trRyt .matchteams figure.vs img {
    max-width: 40px;
  }
}
@media (max-width: 480px) {
  .tourmain .trRyt .matchteams .team1 h2 {
    font-size: 12px;
  }
  .tourmain .trRyt .matchteams .team1 figure {
    width: 30px;
  }
  .tourmain .trRyt .matchteams .team1 figure img {
    max-width: 30px;
  }
}
.top_hdng {
  display: none;
}
@media (max-width: 767px) {
  .top_hdng {
    display: block;
    margin-bottom: 25px;
    h2 {
      text-align: center;
      font-size: 16px;
      color: #fff;
    }
    p {
      color: #ecac35;
      text-align: center;
      padding-top: 10px;
    }
  }
  .trLft:has(+ .trRyt.added) {
    display: none;
  }
}
.prmodl2 {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  background: rgba(0, 0, 0, 0.5);
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: -30px;
    left: -30px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: rgb(236, 172, 53, 0.5);
  }
  &::before {
    top: unset;
    left: unset;
    bottom: -30px;
    right: -30px;
  }
}
