@import "../assets/theme/variable.scss";

.matchmain {
  .outr_mtchcrd {
    .mtch_crd {
      padding: 20px;
      padding-bottom: 12px;
      border-radius: 7px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 20px;
      width: 400px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        left: 0;
        background-image: radial-gradient(
          circle at 75% 0%,
          transparent 12px,
          #e7e6e6 13px
        );
        @media (max-width: 767px) {
          background-image: radial-gradient(
            circle at 67% 0%,
            transparent 8px,
            #e7e6e6 9px
          );
        }
      }
      h3 {
        font-size: 17px;
        padding-bottom: 16px;
      }
      .crd_top {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-bottom: 2px dashed $lightText;
        padding-bottom: 16px;
        .crd_lft {
          display: flex;
          width: 70%;
          figure {
            height: 70px;
            width: 50px;
            margin-right: 10px;
            img {
              object-fit: contain;
            }
          }
          .crd_mdl {
            p {
              font-size: 12px;
              color: $lightText;
              margin-bottom: 2px;
              &:last-child {
                margin: 0;
              }
            }
            h2 {
              font-size: 15px;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 2px;
              // padding: 6px 0px;
            }
            h5 {
              line-height: 15px;
            }
          }
        }
        .mytor {
          display: flex;
          flex-direction: column;
          h3 {
            font-size: 18px;
            font-weight: bolder;
            padding-bottom: 0;
          }
          p {
            color: $primary;
            font-size: 14px;
          }
        }
        .crd_ryt {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          gap: 12px;
          border-left: 1px solid rgba($lightText, 0.2);
          padding-left: 10px;
          width: 24%;
          @media (max-width: 767px) {
            width: 25%;
          }
          p {
            font-size: 12px;
            color: #030303;
          }
        }
      }
      .crd_btm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        .cd_bm_lt {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 70%;
          figure {
            height: 18px;
            width: 40px;
            img {
              max-width: 18px;
            }
          }
          h6 {
            font-size: 12px;
            margin-left: 0px;
            color: #035103;
            text-transform: capitalize;
            font-weight: 500;
          }
        }
        .cd_bm_rt {
          width: 21%;
          h6 {
            font-size: 12px;
            color: $secondary;
            font-weight: 500;
          }
        }
        .crdbtm2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          h5 {
            font-size: 13px;
          }
          .detail {
            h4 {
              background: $pink;
              color: white;
              font-size: 14px;
              padding: 3px 13px;
              border-radius: 4px;
              margin-right: 16px;
            }
            figure {
              height: 25px;
              width: 23px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

//modal css
.prmodal {
  position: relative;
  background: transparent !important;
  box-shadow: unset !important;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 20px !important;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
  }
  &::before {
    left: 0;
    background-image: radial-gradient(
      circle at 0 50%,
      transparent 12px,
      #fff 13px
    );
  }
  &::after {
    right: 0;
    background-image: radial-gradient(
      circle at 100% 50%,
      transparent 12px,
      #fff 13px
    );
  }
  .pricedition {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: url(../../public/static/images/football.png);
      width: 120px;
      height: 170px;
      background-repeat: no-repeat;
      background-size: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      filter: grayscale(1);
    }
    h2 {
      font-size: 20px;
      padding-bottom: 20px;
    }
    .entryfee {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #00000012;
      padding-bottom: 20px;
      margin-bottom: 20px;
      .efleft {
        h4 {
          font-size: 15px;
          font-weight: 400;
        }
      }
      .efRyt {
        h5 {
          font-size: 15px;
          padding-bottom: 7px;
          text-align: right;
        }
        h4 {
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
    .listng {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-bottom: 10px;
      .efleft {
        h4 {
          font-size: 15px;
          font-weight: 400;
        }
      }
      .efRyt {
        h5 {
          font-size: 15px;
          padding-bottom: 7px;
          text-align: right;
        }
      }
    }
  }
}

.pri_cdcl {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pri_cdcl.pri_cdsd {
  justify-content: center;
  position: relative;
}
.prit_crd_mn {
  margin-bottom: 10px;
}
.prdctnCrd {
  .prict_crd {
    margin: 0px 0 15px;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    figure.pri_cdcl_lt {
      height: 65px;
      width: 45px;
      img {
        object-fit: contain;
      }
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      margin-left: 20px;
    }
    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      .cntr_indr {
        figure {
          align-items: center;
          display: flex;
        }
      }
      h2 {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0px;
        max-width: 50px;
        font-family: "Impact";
        text-align: center;
        min-width: 50px;
        overflow: hidden;
      }
      figure {
        cursor: pointer;
      }
    }
    .pri_cdcl_rt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
    }
    .pri_cdcl.pri_cdtd {
      flex-direction: row-reverse;
      .pri_cdcl_rt {
        flex-direction: row-reverse;
        h6 {
          margin-right: 20px;
          margin-left: 0px;
        }
      }
    }
  }
  p {
    text-align: center;
    color: $white;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 12px;
  }
  .mnCrd {
    background: $gray;
  }

  .str_mtch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    .str_img {
      cursor: pointer;
      figure {
        height: 25px;
        width: 25px;
      }
    }
    .versus {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 35px;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #caa33c;
      margin: 0;
    }
    .vrs_rvrs {
      position: absolute;
      top: -30px;
      left: -30px;
      height: 40px;
      width: 60px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: #d9a414;
      border-radius: 0 0 100px 100px;
      border: solid 2px #fff;
      border-top: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      right: 0;
      margin: 0 auto;
      padding-bottom: 6px;
    }
  }
  .str_mtch2 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 40%;

    .versus {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 35px;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #caa33c;
      margin: 0;
    }
    .vrs_rvrs {
      position: absolute;
      top: -30px;
      left: -30px;
      height: 40px;
      width: 60px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: #d9a414;
      border-radius: 0 0 100px 100px;
      border: solid 2px #fff;
      border-top: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      right: 0;
      margin: 0 auto;
      padding-bottom: 6px;
    }
  }
  .mnCrd2 {
    background: linear-gradient(
      180deg,
      #fcc201 0%,
      #eeb609 32%,
      #dba514 54%,
      #c69320 79%,
      #b78628 100%
    );
    h6 {
      color: #030303;
    }
  }
}

.mbl_btn {
  display: none;
}
.trLft {
  .mtch_crd_inr {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    overflow: hidden;
    cursor: pointer;
    h3 {
      font-size: 17px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      left: 0;
      background-image: radial-gradient(
        circle at 75% 0%,
        transparent 12px,
        #e7e6e6 13px
      );
    }
    .crd_top {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      border-bottom: dashed 1px #707070;
      margin-bottom: 10px;
      padding-bottom: 10px;
      margin-top: 10px;
      .crd_lft {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 70%;
        figure {
          width: 40px;
          margin-right: 10px;
        }
        .crd_mdl {
          h2 {
            font-size: 16px;
            line-height: normal;
          }
          p {
            font-size: 12px;
          }
        }
      }
      .mytor {
        width: 30%;
        border-left: 1px solid rgba(104, 104, 104, 0.2);
        padding-left: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h3 {
          font-size: 18px;
          line-height: normal;
          font-weight: 400;
          font-family: "Impact";
        }
        p {
          font-size: 13px;
          line-height: normal;
          font-weight: 300;
          color: #030303;
        }
      }
    }
    .crdbtm2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail {
        display: flex;
        align-items: center;
        figure {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        h4 {
          background: #ff3051 0% 0% no-repeat padding-box;
          border-radius: 3px;
          color: #fff;
          padding: 5px 12px;
          line-height: normal;
          font-size: 13px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .matchmain .mtch_crd {
    width: 35%;
  }
}
@media (max-width: 1200px) {
  .pridct_tp button {
    display: none;
  }
  .pridct_tp {
    justify-content: center;
    text-align: center;
    width: 100%;
    .sb {
      width: 100%;
    }
  }
  .mbl_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  .mbl_btn .btn {
    width: 150px;
    @media (max-width: 767px) {
      margin-top: 20px;
      width: 100%;
      margin-left: 0;
    }
  }
  .prdctnCrd .prict_crd h6 {
    font-size: 14px;
  }
  .prdctnCrd .str_mtch {
    width: 45%;
  }
}
@media (max-width: 992px) {
  .matchmain .mtch_crd {
    width: 49%;
    margin-bottom: 16px;
  }
  .prdctnCrd .prict_crd .pri_cdcl_rt {
    justify-content: flex-start;
    width: 85%;
    flex-wrap: wrap;
    margin-left: 20px;
  }
  .prdctnCrd .prict_crd h6 {
    font-size: 14px;
    margin: 0 0 5px;
    width: 100%;
  }
  .prdctnCrd .prict_crd .pri_cdcl.pri_cdtd .pri_cdcl_rt {
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 20px;
  }
  .prdctnCrd .prict_crd .pri_cdcl.pri_cdtd .pri_cdcl_rt h6 {
    margin-right: 0px;
    text-align: right;
  }
  .prdctnCrd .str_mtch h6 {
    width: auto;
  }
  .prdctnCrd .str_mtch {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .matchmain .outr_mtchcrd {
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    .mtch_crd {
      width: 100%;
    }
  }
  .matchmain .mtch_crd .crd_top .crd_lft .crd_mdl h2 {
    font-size: 14px;
  }
  .matchmain .mtch_crd h3 {
    font-size: 16px;
    padding-bottom: 12px;
  }
  .matchmain .mtch_crd .crd_top .crd_lft figure {
    height: 60px;
    width: 40px;
  }
  .prdctnCrd .prict_crd {
    padding: 12px 10px 12px;
    height: 135px;
    overflow: hidden;
  }
  .prdctnCrd .str_mtch {
    width: 100%;
    flex-direction: column;
  }
  .mnCrd2 .str_mtch {
    padding-top: 20px;
  }
  .prdctnCrd .prict_crd .counter h2 {
    font-size: 16px;
    max-width: 40px;
    min-width: 40px;
  }
  .cntr_indr img {
    max-width: 25px;
  }
  .prdctnCrd .str_mtch .vrs_rvrs {
    top: -48px;
    left: 0;
  }
  .prdctnCrd .str_mtch .str_img {
    margin-bottom: 10px;
  }
  .prdctnCrd .str_mtch h6 {
    margin-bottom: 0;
  }
  .prdctnCrd .prict_crd figure.pri_cdcl_lt {
    width: 65px;
  }
  .pri_cdcl.pri_cdsd {
    width: 20%;
  }
  .pri_cdcl {
    width: 40%;
  }
  .prmodal {
    width: 90%;
  }
  .prmodal .pricedition h2 {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .prdctnCrd .prict_crd h6 {
    font-size: 12px;
  }
  .prdctnCrd .prict_crd .pri_cdcl.pri_cdtd .pri_cdcl_rt {
    margin-right: 5px;
  }
  .prdctnCrd .prict_crd .pri_cdcl_rt {
    margin-left: 5px;
  }
  .prdctnCrd .prict_crd .counter h2 {
    font-size: 13px;
    max-width: 20px;
    min-width: 20px;
  }
  .prdctnCrd .str_mtch .vrs_rvrs {
    top: -42px;
    height: 56px;
    width: 56px;
  }
  .mnCrd2 .str_mtch .vrs_rvrs {
    padding-top: 28px;
  }
  .pri_cdcl {
    width: 41%;
  }
  .prdctnCrd .prict_crd figure.pri_cdcl_lt {
    width: 45px;
    height: 45px;
    position: relative;
    top: -10px;
  }
  .prdctnCrd .prict_crd h6 {
    font-size: 12px;
    font-weight: 600;
  }
  .mnCrd2 .str_mtch h6 {
    margin-top: 10px;
  }
  .pri_cdcl.pri_cdsd {
    width: 18%;
  }
  .prdctnCrd .prict_crd {
    height: 105px;
  }
  .prdctnCrd .str_mtch .versus img {
    max-width: 25px;
  }

  .mnCrd2 .str_mtch .str_img figure {
    width: 35px;
    height: 35px;
  }
  .prdctnCrd .str_mtch .str_img {
    margin-bottom: 0px;
  }
}
