@import "../../assets/theme/variable.scss";
.ftr {
  padding: 30px 0;
  border-top: 2px dashed rgba($secondary, 0.5);
  .mn_ftr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ftr_col {
      // width: 52%;
    }
    .ftr_lft {
      img {
        max-width: 200px;
      }
    }
    .ftr_mdl {
      display: flex;
      justify-content: center;
      align-items: center;
      .scl {
        margin-right: 15px;
        height: 40px;
        width: 40px;
        background: #222222;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        span {
          height: 50% !important;
          width: 50% !important;
          img {
            max-width: 20px;
            filter: invert(63%) sepia(76%) saturate(441%) hue-rotate(358deg)
              brightness(101%) contrast(85%);
          }
        }
      }
    }
    .ftr_ryt {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        margin-right: 20px;
        h6 {
          color: white;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
          @media (max-width: 767px) {
            margin: 0;
            font-size: 13px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .ftr .mn_ftr {
    justify-content: center;
    flex-wrap: wrap;
  }
  .ftr .mn_ftr .ftr_col {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .ftr .mn_ftr .ftr_ryt {
    justify-content: center;
    margin-bottom: 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  .ftr .mn_ftr .ftr_mdl .scl {
    margin-right: 15px;
    height: 30px;
    width: 30px;
  }
  .ftr .mn_ftr .ftr_ryt h6 {
    margin-right: 0px;
    font-size: 12px;
  }
}
.cms {
  min-height: calc(100vh - 100px);
}

@media (max-width: 767px) {
  .ftr.mob_vw {
    padding: 12px 0 20px !important;
  }
}
