@import "../../assets/theme/variable.scss";

.signUpmain {
  padding: 50px 0;
  .sigup {
    width: 50%;
    margin: auto;
    max-width: 555px;
    @media (max-width: 767px) {
      width: 100%;
    }
    a {
      figure {
        height: auto;
        width: 280px;
        @media (max-width: 767px) {
          width: 150px;
        }
      }
    }
    figure {
      width: 150px;
      height: 60px;
      margin: auto;
      margin-bottom: 30px;

      @media (max-width: 767px) {
        width: 150px;
        height: 30px;
        margin-bottom: 20px;
      }
    }

    .close {
      figure {
        width: 30px;
        height: 30px;
        margin-right: 0;
        margin-right: 0;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .signcontainer {
      h4 {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #ecac35;
        padding-bottom: 30px;
        padding-top: 20px;
        @media (max-width: 767px) {
          padding-bottom: 25px;
          color: #fff;
          padding-top: 0;
        }
      }
      .profileimg {
        position: relative;
        margin-bottom: 50px;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        figure {
          width: 150px;
          height: 150px;
          position: relative;
          @media (max-width: 767px) {
            width: 120px;
            height: 120px;
          }
        }
        figure.camera {
          position: absolute;
          width: 45px;
          height: 45px;
          background: white;
          border-radius: 100%;
          padding: 10px;
          bottom: -45px;

          left: 0;
          right: 0;
          cursor: pointer;
          @media (max-width: 767px) {
            width: 35px;
            height: 35px;
            padding: 6px;
            bottom: -15px;
          }
        }
      }
      .inputdiv {
        & > div {
          padding-right: 0 !important;
        }
        input {
          background: #404040;
          margin-bottom: 20px;
        }
        .phoinput {
          margin-bottom: 20px;
          position: relative;
          figure {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 10px;
            right: 20px;
          }
        }
        .valid {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          width: 98%;
          p {
            color: goldenrod;
            background: #daa5202b;
            padding: 10px 40px;
            cursor: pointer;
            border-radius: 5px;
            width: 20%;
            display: flex;
            justify-content: center;
          }
        }
      }
      .haveAcc {
        p {
          color: #666666;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          padding-top: 30px;

          span {
            color: #fff;
            cursor: pointer;
          }
          @media (max-width: 767px) {
            padding-top: 50px;
          }
        }
      }
      .rememberdiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        .remeLft {
          display: flex;
          align-items: center;
          span {
            padding: 0 !important;
            padding-right: 10px;
          }
          h4 {
            font-size: 16px;
            font-weight: 500;
            color: $white;
            cursor: auto;
            padding-left: 10px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: $white;
          cursor: pointer;
          padding-top: 0;
          padding-bottom: 0;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
    .forgotcontainer {
      padding-top: 40px;
      @media (max-width: 767px) {
        padding: 0;
      }
      h4 {
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: white;
        padding-bottom: 18px;
        padding-top: 0;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      p {
        font-size: 17px;
        text-align: center;
        color: #949494;
        padding-bottom: 40px;
        width: 69%;
        margin: auto;
        line-height: 2;
        &.frgt_fw {
          line-height: 26px !important;
          width: 84% !important;
        }
        &.fw_20 {
          font-size: 20px;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        @media (max-width: 767px) {
          font-size: 12px;
          width: 100%;
        }
      }
      .inputdiv {
        & > div {
          padding-right: 0 !important;
        }
        input {
          background: #404040;
          margin-bottom: 20px;
        }
        .otpInput {
          padding-bottom: 30px;
          & > div {
            justify-content: space-between !important;
            input {
              padding: 10px 30px !important;
              outline: none !important;
              border-bottom: 1px solid transparent !important;
              color: white !important;
              font-size: 28px !important;
              margin: 0 !important;
              margin-right: 13px !important;
              width: 25% !important;
              &:last-child {
                margin-right: 0px !important;
              }
              @media (max-width: 767px) {
                padding: 10px !important;
              }
            }
          }
        }
        h3 {
          font-size: 16px;
          text-align: center;
          color: white;
          padding-top: 40px;
          font-weight: 500;
          @media (max-width: 767px) {
            padding-top: 30px;
            font-size: 14px;
          }
        }
        h4 {
          font-size: 16px;
          color: $secondary;
          padding-top: 20px;
          cursor: pointer;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .signcontainer {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        min-height: calc(100vh - 170px);
        .haveAcc {
          margin-top: auto;
        }
      }
    }
  }
  .submit_btn {
    margin-top: 20px;
    padding: 16px 20px;
    font-size: 20px;
    font-weight: 700;
    color: #1b1b1b;
    @media (max-width: 767px) {
      margin: 0;
      font-size: 16px;
    }
    &.mtm_80 {
      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }
}

.linksend {
  figure {
    width: 70px;
    height: 70px;
    margin: auto;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 26px;
    text-align: center;
  }
  p {
    font-size: 15px;
    color: #575757;
    text-align: center;
    word-break: break-all;
    padding-top: 10px;
  }
}

.congrats {
  figure {
    width: 150px;
    height: 130px;
    margin: auto;
    // margin-bottom: 10px;
  }
  h2 {
    color: #292d32;
    text-align: center;
    font-size: 30px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  p {
    color: $secondary;
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.signUpmain {
  background: url("../../../public/static/img/auth_bg.jpg") no-repeat center;
  background-attachment: fixed;
  position: relative;
  background-size: cover;
  min-height: 100vh;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent
      radial-gradient(closest-side at 50% 50%, #1a1a1adb 0%, #1a1a1af0 100%) 0%
      0% no-repeat padding-box;
    z-index: 0;
  }
  @media (max-width: 767px) {
    background: #1a1a1ae6;
    &::after {
      content: unset;
    }
  }
  .container {
    position: relative;
    z-index: 3;
  }
  label.rem_me {
    gap: 15px;
    color: #fff;
    margin-left: 0;
    @media (max-width: 767px) {
      gap: 10px;
    }
    span {
      font-size: 16px;
      font-weight: 500 !important;
    }
    span.MuiButtonBase-root {
      color: #5b5b5b;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
      color: $secondary;
    }
  }
}
.congrat_wrp,
.forgot_pop .forgot_wrp {
  width: 95% !important;
  max-width: 405px;
  min-height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 14px #00000012;
  border-radius: 10px;
  border-radius: 10px;
}
.forgot_wrp {
  .linksend {
    h2 {
      font-size: 30px;
      margin: 30px 0 0px;
      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #575757;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}

label.inp_lbl {
  font-size: 10px;
  position: relative;
  color: $secondary;
  white-space: normal;
  align-items: flex-start;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .mob_block {
    display: block;
  }
  .text_mob_dnger {
    color: #e52134;
  }
  .subscribed_pop {
    h2 {
      font-size: 22px;
    }
    p {
      color: #666666;
    }
  }
}
