@import "../../assets/theme/variable.scss";
.hdr {
  padding: 5px 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  .mn_hdr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hdr_col {
      width: 33%;
    }
    .hdr_col.hdr_btn {
      align-items: flex-end;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
    .hdr_mnu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 6px;
      // cursor: pointer;
      span {
        width: 20px;
        border-radius: 10px;
        height: 2px;
        background-color: white;
        display: inline-block;

        &:nth-child(2) {
          width: 24px;
        }
      }
    }
    .hdr_logo {
      cursor: pointer;
      text-align: center;
      img {
        max-width: 270px;
      }
    }
    button {
      background: #282828;
      border: none;
      color: #ecac35;
      padding: 12px 28px;
      border-radius: 25px;
      text-transform: uppercase;
      font-size: 15px;
      cursor: pointer;
      box-shadow: 0px 3px 36px rgba(145, 145, 145, 0.1607843137);
    }
    .dsply {
      display: block;
    }
    .dsply_nn {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .hdr .mn_hdr .hdr_logo img {
    max-width: 200px;
  }
}
@media (max-width: 767px) {
  .hdr .mn_hdr .hdr_col {
    width: 25%;
  }
  .hdr .mn_hdr .hdr_logo img {
    max-width: 190px;
  }
  .hdr .mn_hdr .hdr_mnu {
    gap: 3px;
  }
  .hdr .mn_hdr .hdr_mnu span {
    width: 15px;
  }
  .hdr .mn_hdr .hdr_mnu span:nth-child(2) {
    width: 18px;
  }
  .hdr .mn_hdr .hdr_col.hdr_logo {
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hdr.mob_vw .mn_hdr {
    position: relative;
  }
  .hdr .mn_hdr button {
    padding: 7px 12px;
    font-size: 11px;
  }
}
@media (max-width: 600px) {
  .hdr .mn_hdr .hdr_col.hdr_mnu {
    width: 18%;
  }
  .hdr .mn_hdr button {
    // padding: 12px 20px;
    // font-size: 13px;
  }
  .hdr .mn_hdr .hdr_logo img {
    max-width: 150px;
  }
}
@media (max-width: 480px) {
  //   .hdr .mn_hdr .hdr_logo img {
  //     max-width: 110px;
  // }
  .hdr .mn_hdr .hdr_col {
    width: 30%;
  }
  // .hdr .mn_hdr button {
  //   padding: 10px 15px;
  // }
  .hdr .mn_hdr .hdr_col.hdr_mnu {
    width: 10%;
  }
  .hdr .mn_hdr .hdr_logo img {
    max-width: 130px;
  }
}
