@import "../../assets/theme/variable.scss";

.yellow_text {
  color: $secondary;
}

.trny_crtd {
  .mn_trny_crtd {
    width: 60%;
    margin: 0 auto;

    .trnys {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .trny_lft {
        color: white;
        position: absolute;
        left: 20px;

        figure {
          width: 120px;
          margin-top: -30px;
          @media (max-width: 767px) {
            width: 95px;
          }

          img {
            object-fit: contain;
          }
        }
      }

      .trny_ryt {
        width: 50%;
        color: white;
        text-align: center;
        padding-left: 0px;

        h2,
        h3,
        h4,
        h5 {
          color: #fff;
        }

        .yellow_text {
          color: #ecac35;
        }

        h4 {
          font-size: 26px;
          font-weight: 500;
          line-height: 46px;
        }

        .crtd {
          text-transform: uppercase;
          padding-bottom: 10px;
        }

        .pb_bt {
          padding: 10px 0;
          @media (max-width: 767px) {
            padding: 4px 0;
          }
        }

        h5 {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
        }

        h2 {
          font-size: 46px;
          line-height: 70px;
          font-family: "Impact";
          font-weight: 400;
        }
      }
    }

    .btn_dv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 50px;

      button {
        width: 49%;
      }
      p {
        padding: 30px 0;
        font-size: 12px;
        color: white;
        text-align: center;
        line-height: 20px;
        font-weight: 300;
        width: 100%;
      }
    }
  }
  .season_tym {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    &.season_tym2 {
      padding: 20px 120px 20px 15px;
      // background: url("../../../public/static/img/add_entry_bg.png");
      display: flex;
      justify-content: space-between;
      background-size: cover;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        padding: 10px 100px 10px 10px;
        background-position: center right;
      }
      h6 {
        font-size: 16px;
        font-weight: 500;
        font-style: italic;
        color: $secondary;
        @media (max-width: 767px) {
          font-size: 10px;
        }
      }
      p {
        font-size: 12px;
        color: #fff;
        margin-top: 7px;
        font-weight: 500;
        span {
          font-size: 12px;
          color: #fff !important;
          background-color: transparent !important;
        }
        @media (max-width: 767px) {
          font-size: 9px;
          margin-top: 6px;
        }
      }
      .ssn_ryt {
        text-align: right;
        h6 {
          font-size: 13px;
          @media (max-width: 767px) {
            font-size: 9px;
          }

          span {
            font-size: 16px;
            font-family: "Impact";
            font-weight: 400;
            @media (max-width: 767px) {
              font-size: 10px;
              display: block;
            }
          }
        }
        p {
          color: #ff3b3b;
          font-style: italic;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .trny_crtd .mn_trny_crtd {
    width: 80%;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt {
    width: 70%;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h4 {
    font-size: 22px;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h2 {
    font-size: 36px;
    line-height: 60px;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h4 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .trny_crtd {
    height: 100vh;
  }

  .trny_crtd .mn_trny_crtd .trnys {
    flex-wrap: wrap;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_lft {
    position: unset;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h2 {
    font-size: 26px;
    line-height: 40px;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h2 {
    font-size: 39px;
    font-weight: 400;
  }
  .trny_crtd .mn_trny_crtd {
    width: 100%;
  }

  .trny_crtd .mn_trny_crtd .btn_dv {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 30px;
    button {
      width: 100%;
      margin-bottom: 15px;
      &.mob_bottom {
        order: -4;
      }
    }
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h5 {
    font-size: 12px;
  }

  .trny_crtd .mn_trny_crtd .trnys .trny_ryt h4 {
    font-size: 16px;
    line-height: normal;
  }
}
