@import "../../assets/theme/variable.scss";
.entrydiv {
  padding-top: 40px;
  position: relative;
  padding-bottom: 70px;
  @media (max-width: 767px) {
    padding: 20px 0 40px;
  }

  &::after {
    content: "";
    position: absolute;
    background: url(../../../public/static/images/modalbg.png);
    width: 30%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    bottom: 0;
    margin: auto;
    opacity: 0.1;
    @media (max-width: 767px) {
      background-position: center;
      opacity: 0.3;
    }
  }
  .entry {
    margin-bottom: 20px;
    cursor: pointer;
    h3 {
      span {
        font-style: italic;
      }
    }
    h2 {
      color: $white;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
@media (max-width: 1200px) {
  .entrydiv .entry h2 {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .entrydiv .entry h2 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .entrymain {
    .sb {
      h2 {
        font-size: 16px;
        text-align: center;
      }
    }
    .entrydiv .entry h2 {
      font-size: 12px;
    }
    .entrydiv .entry h4,
    .entrydiv .entry h3 {
      font-size: 12px;
    }
    .entrydiv .entry h3 span {
      font-size: 10px;
    }
  }
  .entrydiv .entry {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
