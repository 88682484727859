.bonmain {
  .bonusBnr {
    margin-top: 30px;
    padding: 60px 40px 50px;
    border-radius: 5px;
    // background: url(../../../public/static/images/);
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    // position: relative;
    @media (max-width: 1200px) {
      background-size: cover;
      background-position: center right;
    }
    @media (max-width: 767px) {
      padding: 20px 12px;
      background-position: 93% center;
    }

    top: 30px;
    h1 {
      font-size: 40px;
      color: white;

      font-family: "Montserrat Alternates", sans-serif;
      @media (max-width: 991px) {
        font-size: 28px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        .mob_block {
          display: inline;
        }
      }
    }
    p {
      color: white !important;
    }
    h3 {
      font-size: 25px;
      font-weight: 400;
      width: 60%;
      line-height: 1.5;
      color: white;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    h4 {
      font-size: 22px;
      color: white;
      font-weight: 500;
      padding-top: 30px;
      @media (max-width: 767px) {
        font-size: 10px;
        padding-top: 16px;
      }
      span {
        color: #ecac35;
        font-size: 25px;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
  .bonusBnrr {
    margin-top: 20px;
    padding: 80px 40px 70px;
    border-radius: 5px;

    background: url(../../../public/static/images/bg3.png);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 991px) {
      padding: 30px 20px 30px;
    }
    @media (max-width: 767px) {
      padding: 20px 15px;
      background-position: 70%;
    }
    h1 {
      font-size: 40px;
      color: white;
      padding-bottom: 15px;
      font-family: "Montserrat Alternates", sans-serif;
      @media (max-width: 991px) {
        font-size: 28px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        padding-bottom: 10px;
      }
    }
    p {
      color: white !important;
    }
    h3 {
      font-size: 25px;
      font-weight: 400;
      width: 60%;
      color: white;
      line-height: 1.6;
      @media (max-width: 1200px) {
        font-size: 20px;
        max-width: 50%;
      }
      @media (max-width: 767px) {
        font-size: 12px;
        max-width: 60%;
        line-height: 18px;
      }
    }
    h4 {
      font-size: 21px;
      color: white;
      font-weight: 500;
      padding-top: 15px;
      span {
        color: #ecac35;
      }
    }
  }
  .datediv {
    display: flex;
    width: 60%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 30px 0px;
    @media (max-width: 1200px) {
      width: 80%;
    }
    @media (max-width: 767px) {
      padding: 10px 0 20px;
      width: 100%;
    }
    h3 {
      color: #fff;
      font-weight: 300;
      font-size: 21px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  .tireddiv {
    padding: 50px 0 25px;
    word-wrap: break-word;
    max-width: 100%;

    span {
      background-color: transparent !important;
      color: white !important;
      font-family: "Montserrat", sans-serif !important;
    }
    @media (max-width: 1200px) {
      padding: 0;
      padding-top: 30px;
    }
    @media (max-width: 767px) {
      padding: 0;
      &.mt_40 {
        margin-top: 20px;
      }
    }
    h3 {
      color: #ecac35;
      font-size: 27px;
      font-weight: 700;
      text-align: center;
      padding-bottom: 35px;
      @media (max-width: 1200px) {
        padding-bottom: 20px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        text-align: left;
        line-height: 20px;
      }
    }
    p {
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      color: white;
      margin-bottom: 25px;
      line-height: 30px;
      @media (max-width: 767px) {
        margin-bottom: 16px;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
      }
    }
  }
  h5 {
    font-size: 16px;
    text-align: center;
    color: #ecac35;
    font-weight: 400;
    margin-top: 20px;
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
  .btndiv {
    margin: auto;
    width: 30%;
    margin-top: 50px;
    @media (max-width: 1200px) {
      margin-top: 30px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 18px 0 0;
    }
    button {
      width: 100%;
      margin: auto;
      font-size: 20px;
      padding: 18px;
      font-weight: 700;
      @media (max-width: 767px) {
        padding: 13px;
        font-size: 16px;
      }
    }
  }
  h2 {
    color: white;
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
  }
}
.nv_spc_bns {
  padding-top: 155px;
  @media (max-width: 1200px) {
    padding-top: 30px;
  }
}
.ext_bn_wrp {
  h2 {
    font-size: 30px;
    font-weight: 500;
    &.fw_24 {
      font-size: 24px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      text-align: center;
      &.fw_24 {
        font-size: 21px;
        padding-top: 60px;
      }
    }
  }
}

.bns_tp_flx {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: flex-end;
  padding-bottom: 25px;
  @media (max-width: 767px) {
    padding-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
}
.mt_autom_mov {
  p {
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
@media (max-width: 767px) {
  .bonmain {
    display: flex;
    flex-direction: column;
    // min-height: calc(100vh - 200px);
  }
  .mt_autom_mov {
    margin-top: auto;
    padding-top: 30px;
  }
  .tourmain .trRyt .bonmain .bonusBnr {
    margin-top: 0;
  }
}

.sel_week {
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
