@import "/src/assets/theme/variable.scss";
@mixin d_flx($align: center, $justy: flex-start, $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justy;
  gap: $gap;
  flex-wrap: wrap;
}
.container {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
}
* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
  background: $primary;
}
.btn {
  font-family: "Montserrat", sans-serif !important;
}
.text_center {
  text-align: center;
}
@font-face {
  font-family: "Impact";
  src: url("../public/static/fonts/Impact.woff2") format("woff2"),
    url("../public/static/fonts/Impact.woff") format("woff"),
    url("../public/static/fonts/Impact.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Impact";
  src: url("../public/static/fonts/Impact_1.woff2") format("woff2"),
    url("../public/static/fonts/Impact_1.woff") format("woff"),
    url("../public/static/fonts/Impact_1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Impacted";
  src: url("../public/static/fonts/Impacted.woff2") format("woff2"),
    url("../public/static/fonts/Impacted.woff") format("woff"),
    url("../public/static/fonts/Impacted.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure {
  margin: 0;
}
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
p,
li,
a {
  color: #686868;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #030303;
}
.countr_wdh {
  width: 10px;
}
.txt_fld p {
  color: red !important;
  margin-top: -16px !important;
  margin-bottom: 10px !important;
  margin-left: 0 !important;
  text-align: left !important;
  font-size: 12px !important;
  padding: 0 !important;
}
.validation_msg {
  color: red;
  font-size: 12px;
  margin-top: -16px !important;
  margin-bottom: 10px !important;
  font-weight: 500;
  &.otp_err {
    margin-top: 6px !important;
  }
}
.validation_msg2 {
  font-size: 12px;
  font-weight: 500;
  color: red;
  margin-top: -14px;
}
.react-tel-input {
  margin-bottom: 20px !important;
}
.nv_spc {
  padding-top: 100px;
  @media (max-width: 767px) {
    padding-top: 40px;
  }
}
img {
  max-width: 100%;
}
.all_sc {
  padding: 70px 0 20px;
}
.blk_span {
  display: block;
}
.sb_hd {
  h2 {
    font-size: 30px;
    text-align: center;
    color: $secondary;
    text-transform: uppercase;
    font-weight: 300;
  }
}
.sb {
  h2 {
    color: $white;
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 10px;
  }
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: $primary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}
.drwr {
  background-color: rgba(0, 0, 0, 0.32) !important;
  .MuiDrawer-paperAnchorLeft {
    width: 100% !important;
    background: transparent !important;
    box-shadow: none !important;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
  .mnu {
    padding-left: 130px;
    padding-top: 150px;
    height: 100vh;
    box-sizing: border-box;
    div {
      padding-top: 30px;
      button {
        background: transparent;
        border: none;
      }
      h4 {
        font-size: 30px;
        font-weight: 500;
        cursor: pointer;
        color: $white;
      }
    }
  }
}
body {
  .btn {
    background: $secondary;
    color: $primary;
    padding: 14px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 2px;
    cursor: pointer;
    min-width: 120px;
  }
}
.hdng {
  color: $white;
  font-size: 22px;
  font-weight: 500;
}
.wrapCls {
  padding: 140px 0;
}
.adornment {
  position: relative;
  & > div {
    padding-right: 0 !important;
  }
}
.iconador {
  position: absolute;
  right: 30px;
  top: 15px;
  cursor: pointer;
}

fieldset {
  display: none;
}

::placeholder {
  color: white !important;
  opacity: 1;
}

.entrydiv {
  padding-top: 20px;
  .entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ffd058;
    padding: 20px;
    @media (max-width: 767px) {
      gap: 18px;
      flex-wrap: wrap;
      justify-content: center;
    }
    h3 {
      color: $white;
      font-size: 20px;
      font-weight: 600;
      span {
        font-size: 12px;
        color: #808080;
        padding-right: 10px;
      }
    }
    h4 {
      color: $secondary;
      font-size: 20px;

      font-weight: 600;
    }
  }
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #f7f4f4 !important;
  box-shadow: none !important;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0px 20px 20px 20px !important;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: 404040;
  width: 400px !important;

  overflow-y: scroll;
  border-radius: 0px !important;
  height: 600px !important;
  max-height: 250px !important;
  @media (max-width: 1280px) {
    width: 300px !important;
  }
  @media (max-width: 992px) {
    width: 230px !important;
  }
}
.react-tel-input .form-control {
  line-height: 25px !important;
  font-size: 16px;
  background: #404040 !important;

  /* border-radius: 5px; */
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
  border: none !important;
  border-radius: 0px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  height: 54px !important;
  &:focus {
    border-color: "red" !important;
    box-shadow: none !important;
  }
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: red;
  padding: 10px 0 6px 10px;
  width: 100%;
}
.react-tel-input .country-list .search-box {
  border: 1px solid red !important;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 7px 11px 9px !important;
  outline: none;
  width: 94%;
  box-sizing: border-box;
  &::placeholder {
    text-transform: capitalize;
  }
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #404040 !important;
  border: 1px solid #404040 !important;
  border-radius: 3px 0 0 3px;
  &:hover {
    background-color: #404040 !important;
  }
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #404040 !important;
}

.inputdiv {
  input,
  select,
  textarea,
  input[class],
  select[class],
  textarea[class] {
    background: #404040;
    margin-bottom: 20px;
    border-radius: 2px;
    color: #fff;
    font-size: 16px;
    padding: 16.5px 14px;
    width: 100%;
    height: auto !important;
    @media (max-width: 767px) {
      height: auto !important;
      padding: 14px 16px;
      font-size: 14px;
    }
    &::placeholder {
      color: #fff;
      opacity: 0.7;
    }
  }
  .eye_icon {
    color: #fff;
    opacity: 0.7;
    background: transparent !important;
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .select * {
    color: #fff !important;
  }
  .select p {
    opacity: 0.7;
  }
  .phoinput {
    margin-bottom: 20px;
    position: relative;
    .react-tel-input .form-control {
      padding-left: 60px !important;
    }
    figure {
      position: absolute;
      width: 30px !important;
      height: 30px !important;
      top: 0px;
      bottom: 0;
      right: 20px;
      @media (max-width: 575px) {
        width: 30px !important;
        height: 30px !important;
        top: 0;
        bottom: 0;
      }
      img {
        @media (max-width: 575px) {
          object-fit: contain;
        }
      }
    }
  }
}
.cmn_bg {
  background: url(../public/static/images/prdct_bgimg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 575px) {
    min-height: 100vh;
  }
}
.cm_trny_img {
  background: url(../public/static/images/pridt_cmm_bn.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    padding-top: 80px;
  }
}

@media (max-width: 1200px) {
  .all_sc {
    padding: 50px 0 20px;
  }
}

@media (max-width: 767px) {
  .all_sc {
    padding: 40px 0 20px;
  }
  .entrydiv .entry h3 {
    font-size: 18px;
  }
  .entrydiv .entry h4 {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .entrydiv .entry h3 {
    font-size: 16px;
  }
  .entrydiv .entry h4 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .sb_hd h2 {
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .entrydiv .entry h3 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column-reverse;
    span {
      padding-right: 0;
    }
  }
  .entrydiv .entry h3 {
    font-size: 14px;
  }
  .entrydiv .entry h4 {
    font-size: 14px;
  }
}

/* 26-27may */
.cursor_pointer {
  cursor: pointer;
}
.drwr:has(.chat_otr) {
  height: 100vh;
  overflow: auto;
}
.drwr .mnu div h4 {
  text-transform: uppercase;
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  transition: all 0.2s linear;
  &:hover {
    border-bottom: 2px solid $secondary;
    color: $secondary;
  }
  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.page_bg {
  position: relative;
  padding-bottom: 60px;
  background-image: url("../public/static/img/auth_bg.jpg");
  background-repeat: no-repeat;
  // background-size: contain;
  background-size: cover;
  background-position: top center;
  .container {
    position: relative;
    z-index: 3;
  }
  &::after {
    content: "";
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    background: url("../public/static/img/profile_bfr_img.png") no-repeat top
      center;
    background-size: contain;
    pointer-events: none;
    min-height: 730px;
    width: 830px;
    z-index: 0;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent
      radial-gradient(closest-side at 50% 50%, #1a1a1adb 0%, #1a1a1af0 100%) 0%
      0% no-repeat padding-box;
    pointer-events: none;
    z-index: 0;
  }
  @media (max-width: 767px) {
    padding-bottom: 30px;
    min-height: 100vh;
  }
}
.mob_vw_icon,
.only_mob {
  display: none;
}
.italic {
  font-style: italic;
}
.text_primary {
  color: $secondary;
}
.selct_head {
  p {
    font-size: 14px;
    margin-top: 3px;
  }
}
.text_danger {
  color: #ff3b3b;
}
.matchmain_mob .mtch_crd_inr {
  display: none;
}
.tourmain .trRyt .tourmain .trLft {
  width: 100%;
}
.tourmain .trRyt .bonmain .bonusBnr {
  padding: 20px 15px;
}
.trRyt .bonmain .bonusBnr h1 {
  font-size: 16px;
  margin-bottom: 10px;
}
.trRyt .bonmain .bonusBnr h3 {
  font-size: 12px;
}
.trRyt .bonmain .bonusBnr h4 {
  padding-top: 20px;
}
.trRyt .bonmain .bonusBnr h4,
.trRyt .bonmain .bonusBnr h4 span {
  font-size: 13px;
}
.tourmain .trRyt .matchmain_mob.trLft {
  width: 100%;
  margin-top: 15px;
  position: unset;
}

.tourney_tm {
  text-align: center;
  color: #ecac35;
  padding-top: 10px;
  display: none;
}
.ldrbrd_dtls .tabs {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .srch_mob_top {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;
    position: relative;
  }
  .ldrbrd_dtls .srch_mob_top .tbs_lft {
    margin-top: 0;
  }
  .ldrbrd_dtls .srch_mob_top .tab_ryt {
    top: -113px;
  }
  .matchmain_mob .mtch_crd_inr {
    display: block !important;
  }
  .tourney_tm {
    text-align: center;
    color: #ecac35;
    padding: 20px;
    display: block !important;

    .sb.dfgfg {
      h2 {
        font-size: 30px;
        margin-bottom: 8px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        font-style: italic;
      }
    }
    @media (max-width: 767px) {
      .sb.dfgfg {
        h2 {
          font-size: 16px;
        }
        p {
          font-size: 12px;
        }
      }
      .tourmain .trLft .bonmain .bonusBnr h1 {
        font-size: 12px;
        margin-bottom: 8px;
      }
      .tourmain .trLft .bonmain .bonusBnr h3,
      .tourmain .trLft .bonmain .bonusBnr h4 span {
        font-size: 10px;
      }
      .mob_vw_icon {
        display: block;
        figure {
          width: 40px;
          height: 40px;
          margin: 0 auto;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
      .mob_block,
      .matchmain_mob,
      .only_mob,
      .matchmain_mob .mtch_crd_inr {
        display: block;
      }
      .bonmain_tour {
        min-height: unset !important;
      }
      .bonmain_tour .bonusBnr {
        margin-top: 0 !important;
      }
      .bg.bnr + .prfct_sc {
        padding-top: 0;
      }
      .hdr,
      .ftr {
        display: none;
      }
      .mob_block {
        display: block;
      }
      .drwr .mnu {
        padding-left: 0px;
        padding-top: 100px;
        text-align: center;
      }
      .entrydiv .entry {
        padding: 12px 20px;
        border: 1px solid #ffd05829;
        border-radius: 4px;
      }
    }
  }
  .mob_vw.mob_vw_icon {
    display: block;
  }
  .drwr .mnu {
    padding: 20px;
    text-align: center;
  }
  .hdr,
  .ftr {
    display: none;
  }
  .mob_vw_icon,
  .only_mob {
    display: block;
  }
}

// loader
.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  figure {
    width: 50px;
    height: 50px;
  }
}
.invite_frnds {
  border: 1px dashed white;
  border-radius: 30px;
  position: relative;
  background: #2d2d2d !important;
  padding: 10px;
  .copy_link {
    color: white !important;
    text-align: left !important;
    padding: 0 !important;
  }
  .copy_icn {
    position: absolute;
    right: 16px;
    top: 7px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
.subscribed_txt {
  color: #ff3051;
  border: 1px solid #ff3051;
  border-radius: 30px;
  padding: 6px 8px;
  font-size: 14px;
  margin-right: 8px;
}
.dtpckr {
  padding: 10px;
  background: #404040;
  border: none;
  width: 100% !important;
  color: white;
}
.dt_pckr_txt {
  color: white;
  padding: 6px 0;
  font-size: 14px;
}
.no_data {
  color: white;
  position: relative;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
}
.tabsssss {
  display: none !important;
}

.react-datepicker__navigation-icon--next {
  position: absolute;
  right: 0px;
}

.react-datepicker__navigation-icon--previous {
  position: absolute;
  left: 0px;
}
.info-pre {
  display: flex;
  align-items: center;
  svg {
    color: #ecac35;
    margin-left: 10px;
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
}

.str_drw {
  width: 20px;
  height: 20px;
  margin-top: 30px;
  margin-left: 20px;
}
.match_tms {
  background: $secondary !important;
}
.highlighted_card {
  border: 3px solid #ecac34;
}
.clr_hdng {
  color: #ecac34 !important;
  font-size: 18px !important;
  padding-top: 10px !important;
}

//pagination
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column !important;
    justify-content: center !important;
    gap: 10px;
  }
  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
  }
  p {
    font-size: 14px;

    span {
      // font-family: "bold";
      color: #ecac34;
    }
  }
  .pages {
    display: flex;
    .prevbtn {
      background: no-repeat;
      border: none;
    }
    svg {
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      color: #ecac35;
      cursor: pointer;
    }
    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #a5a5a5;
      .actv {
        background: #ecac35;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        border: none;
        color: black;
      }
      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        border: none;
        color: white;
      }
    }
  }
}
.hdr_tokens {
  font-size: 17px;
  font-weight: 400;
  color: #ecac35;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
}
.points_detail {
  display: flex;
  justify-content: flex-end;
  width: 250px;
  // div {
  //   margin-left: 30px;
  // }
}
.hdr_tkns2 {
  font-size: 12px !important;
}

.user1 {
  border-bottom: 1px solid #ffffff1f;
  cursor: pointer;
  .userleft {
    display: flex;
    align-items: center;
    figure {
      width: 50px;
      height: 50px;
      @media (max-width: 575px) {
        width: 35px;
        height: 35px;
      }
      img {
        border: 1px solid #ffffff36;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        @media (max-width: 575px) {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 1px solid #ffffff36;
        }
      }
    }
    h4 {
      font-size: 16px;
      font-weight: 300;
      color: $white;
      margin-left: 20px;
      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
  }
  h3 {
    color: $secondary;
    font-size: 16px;
    letter-spacing: -0.16px;
    font-weight: 300;
    text-align: center;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
}
.head1 {
  padding-bottom: 15px;
  th:first-child {
    h5 {
      text-align: left;
    }
  }
  h5 {
    font-size: 16px;
    font-weight: 300;
    color: white;
    text-align: center;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
}

.table_listing {
  th {
    padding: 0px 15px 15px;
    @media (max-width: 575px) {
      padding: 0px 10px 5px;
    }
  }
  td {
    padding: 15px;
    @media (max-width: 575px) {
      padding: 10px 5px;
    }
  }
  .newentry {
    font-size: 18px;
    width: 70%;
    padding: 15px;
    font-weight: 900;
    margin: 30px auto 0;
    cursor: pointer;
    background: $secondary;
    color: $primary;
    outline: unset;
    display: block;
    border: 0;
    @media (max-width: 767px) {
      font-size: 16px;
      width: 100%;
      margin: 30px 0 10px;
    }
  }
}

@media (max-width: 768px) {
  .proryt_mobww {
    display: none !important;
  }
}

.proryt_mobww2 {
  display: flex !important;
}
@media (max-width: 768px) {
  .mob_dsply {
    display: none;
  }
}
.reg_txt_sz {
  font-size: 16px !important;
  padding-top: 6px;
  @media (max-width: 992px) {
    font-size: 14px !important;
    padding-top: 3px;
  }
}
.season_hdng {
  p {
    background-color: transparent !important;
    color: white !important;
  }
}
.trnsctns {
  .trnsctns_lft {
    max-width: 445px;
    width: 100%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
    h4 {
      font-size: 14px;
      @media (max-width: 768px) {
        font-size: 12px;
        text-align: left;
      }
    }
  }
  .trnsctns_ryt {
    p {
      font-size: 14px;
      @media (max-width: 768px) {
        padding-top: 6px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}

.golden_txt {
  color: #f09f06 !important;
}
.silver_txt {
  color: #f09f06 !important;
}
.bronze_txt {
  color: #f09f06 !important;
}
.arrw_btn_trny {
  position: fixed;
  background: #1a1a1a;
  color: #ecac35;
  right: 60px;
  bottom: 80px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none !important;
  }
}
.prfl_autocmplt {
  label {
    background-color: white !important;
  }
  & > div {
    border: 1px solid #ecac35;
    margin-top: 9px;
    border-radius: 4px;
    input::placeholder {
      color: black !important;
      opacity: 1 !important;
    }
  }
}
.top_hdng_prid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pdf_filters {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-wrap: wrap !important;
  }
  .season_autocmplt {
    width: 400px !important;
    color: white !important;
    @media (max-width: 1200px) {
      width: 300px !important;
    }
    @media (max-width: 992px) {
      width: 250px !important;
    }
    @media (max-width: 767px) {
      width: 200px !important;
    }
    @media (max-width: 600px) {
      width: 100% !important;
      padding-bottom: 14px;
    }
    label {
      background-color: #1a1a1a !important;
      color: white;
    }
    svg {
      color: white !important;
    }
    & > div {
      border: 1px solid #ecac35;
      // margin-top: 9px;
      max-height: 50px !important;
      border-radius: 4px;
      input {
        color: white;

        &::placeholder {
          color: white !important;
        }
      }
    }
  }
}
.pri_btn {
  @media (max-width: 1200px) {
    font-size: 16px !important;
  }
  @media (max-width: 600px) {
    font-size: 14px !important;
  }
}
.user_txtfld {
  .Mui-disabled {
    opacity: 1 !important;
    -webkit-text-fill-color: white !important;
    cursor: no-drop;
  }
}
.web_nodata {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.mob_nodata {
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
}
.leaderbrd_imgs {
  span {
    border-bottom: 1px dashed transparent !important;
    margin: 0 !important;
  }
}
.table_res {
  width: 100%;
  overflow: auto;
}
.transactns_lft {
  display: flex;
  align-items: center;
  gap: 30px;
  @media (max-width: 1200px) {
    gap: 20px;
  }
  .trnsctn_title_dv {
    h4 {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: bold;
      color: #ecac35 !important;
      text-align: left !important;
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
    p {
      color: gray;
      padding-top: 6px;
      font-size: 14px;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  .trnsctn_des_dv {
    p {
      font-size: 14px;
      color: white;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
}
.transactns_ryt {
  min-width: 60px;
  h4 {
    font-size: 16px !important;
    font-weight: bold;
    text-align: right;
    @media (max-width: 480px) {
      font-size: 14px !important;
    }
  }
  .green_text {
    color: green;
  }
  .red_text {
    color: red;
  }
  p {
    font-size: 14px;
    padding-top: 6px;
    text-align: right;
    color: white;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
}
.pagination_mn {
  display: flex;
  align-items: center;
  gap: 20px;
  .dropse {
    // border: 1px solid #ecac35;
    height: 40px;
    color: #ecac35;
    svg {
      color: #ecac35;
    }
  }
}
.prmodl2 {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gray_txt {
  color: #cccccc !important;
  padding: 0 4px;
  line-height: 22px;
  @media (max-width: 480px) {
    padding: 0;
  }
}
