@import "../../assets/theme/variable.scss";

.cms_hdng {
  color: white;
  text-align: center;
  font-size: 26px;
  padding-bottom: 30px;
}

.policy_txt {
  padding-bottom: 30px;
  p {
    color: white !important;
    font-size: 14px !important;
    line-height: 26px;
  }
}
.policy_txt2 {
  color: white !important;
  font-size: 14px !important;
  line-height: 26px;
  text-align: center;
}
