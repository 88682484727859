@import "../../assets/theme/variable.scss";

.cm_trny_img2 {
  background: url("../../../public/static/images/pridt_cmm_bn.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
}
.nw_wdth {
  width: 85% !important;
  @media (max-width: 1200px) {
    width: 70% !important;
  }
  @media (max-width: 992px) {
    width: 57% !important;
  }
  @media (max-width: 600px) {
    width: 80% !important;
  }
  @media (max-width: 480px) {
    width: 90% !important;
  }
}
.pnts_clcltn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.pnts_dv {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 45px;
    width: 1px;
    background: #ecac35 !important;
    top: 7px;
    right: -30px;
    @media (max-width: 600px) {
      right: -18px;
    }
  }
  &:last-child::after {
    display: none !important;
  }
}

.sb {
  @media (max-width: 1200px) {
    text-align: center !important;
  }
  h2 {
    color: #ffffff;
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }
}
