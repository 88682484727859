@import "../../assets/theme/variable.scss";

.pridct_sc {
  .pridct_tp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 120px;
      margin-left: 20px;
      min-width: max-content;
      &.sv_btn {
        min-width: 200px;
        padding: 12px 30px;
        width: auto;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  .detail_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    .detail_text {
      font-size: 16px;
      font-weight: 600;
      color: #ecac35;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  .btm_prdctn {
    padding: 20px 0 80px;
    @media (max-width: 767px) {
      padding: 20px 0 40px;
    }
  }
}
.info_modl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  max-width: 410px;
  width: 95% !important;
  figure {
    height: 100px;
    width: 100px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      width: 80px;
      height: 80px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  p {
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #292d32;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .gold_txt {
    color: $secondary;
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
.prdctn_drwr {
  background: #1b1b1b !important;
  width: 670px;
  overflow: auto;
  padding: 30px 0;
  height: 100vh;
  overflow: hidden;
  .pridt_mn {
    width: 70%;
    margin: 0 auto;
    .pridt_tp {
      color: white;
      text-align: center;
      padding: 0px 0 20px;
      @media (max-width: 767px) {
        padding: 0 0 20px 0;
      }
      p.pritd_par {
        letter-spacing: 0px;
        color: #ecac35;
        font-size: 15px;
        line-height: normal;
        font-weight: 400;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .pridt_crd {
      max-height: calc(100vh - 274px);
      overflow-y: auto;
      padding-right: 10px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        padding-right: 0;
        max-height: unset;
      }

      .prdctn_crd {
        background: #e0dbdb;
        border-radius: 10px;
        padding: 0px 20px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          margin-bottom: 18px;
        }
        .itm_chkd {
          position: absolute;
          top: 6px;
          left: 6px;
        }
        .crd_lt {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 60%;
          padding: 20px 0;
          figure {
            width: 40px;
            margin-right: 20px;
          }
          .crd_lt_cntn {
            h3 {
              font-size: 20px;
              line-height: normal;
              font-weight: 600;
              span {
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
        .crd_rt {
          width: 40%;
          text-align: center;
          border-left: dotted 10px #030303;
          padding: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 50px;
            line-height: normal;
            font-weight: 700;
            font-family: "Impact";
            span {
              font-size: 18px;
              font-weight: 500;
              text-transform: uppercase;
              font-family: "Montserrat", sans-serif;
            }
          }
        }
      }
    }
    .pred_btm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      @media (max-width: 767px) {
        margin-right: 10px;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: dashed 2px #fff;
      }
      h2 {
        color: #fff;
        font-size: 50px;
        background: #1b1b1b;
        position: relative;
        z-index: 1;
        padding-left: 10px;
      }
      h3 {
        color: #fff;
        font-size: 20px;
        background: #1b1b1b;
        position: relative;
        z-index: 1;
        padding-right: 10px;
        @media (max-width: 767px) {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    button {
      width: 100%;
      margin-top: 20px;
      @media (max-width: 767px) {
        font-size: 16px;
        margin-top: 25px;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt h2 {
    font-size: 40px;
  }
  .prdctn_drwr .pridt_mn .pred_btm h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_lt .crd_lt_cntn h3 {
    font-size: 18px;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt h2 {
    font-size: 30px;
  }
  .prdctn_drwr .pridt_mn .pred_btm h2 {
    font-size: 30px;
  }
  .prdctn_drwr .pridt_mn {
    width: 90%;
  }
  .drwr .MuiPaper-root.MuiPaper-elevation {
    width: 100%;
  }
  .prdctn_drwr {
    width: 100%;
    overflow: auto;
  }
}
@media (max-width: 600px) {
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt h2 {
    font-size: 25px;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt h2 span {
    font-size: 13px;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt {
    width: 35%;
    border-left: dotted 8px #1b1b1b;
    position: relative;
    margin: 11px 0;
    &::before,
    &::after {
      content: "";
      background: #1b1b1b;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      position: absolute;
      top: -15px;
      left: -8px;
    }
    &::before {
      top: auto;
      bottom: -15px;
    }
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_lt .crd_lt_cntn h3 {
    font-size: 16px;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_lt {
    padding: 20px 0;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt {
    padding: 20px 0;
  }
  .prdctn_drwr .pridt_mn .pred_btm h2 {
    font-size: 40px;
  }
}
@media (max-width: 480px) {
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_lt figure {
    width: 30px;
    margin-right: 10px;
  }
  .prdctn_drwr .pridt_mn {
    width: 95%;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_rt h2 {
    font-size: 39px;
    font-weight: 400;
    span {
      font-size: 14px;
    }
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_lt .crd_lt_cntn h3 span {
    font-size: 10px;
  }
  .prdctn_drwr .pridt_mn .pridt_crd .prdctn_crd .crd_lt .crd_lt_cntn h3 {
    font-size: 16px;
    margin-top: 4px;
    font-weight: 700;
  }
}

@media (max-width: 767px) {
  .drwr .mob_vw figure {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .pridct_sc .pridct_tp h2 {
    font-size: 16px;
  }
  .pridct_sc .pridct_tp .bck_btn {
    margin-top: -20px;
    position: relative;
    top: 4px;
  }
  .pridt_tp {
    .sb {
      h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 6px;
      }
    }
  }
  .prdctn_drwr .pridt_mn .pridt_crd {
    max-height: unset;
  }
}
