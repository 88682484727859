@import "../../assets/theme/variable.scss";

.tablediv {
  padding-top: 30px;
  padding-bottom: 60px;
  & > div {
    background: transparent !important;
    color: $white !important;
    table {
      thead,
      tbody {
        tr {
          th {
            font-family: "Montserrat", sans-serif !important;
            border-bottom: 1px solid #404040 !important;
            border-right: 1px solid #404040 !important;
            padding: 10px 10px !important;
            color: $secondary !important;
            font-size: 12px !important;
            @media (max-width:767px){
              font-size: 10px !important;
              white-space: nowrap;
            }
            &:first-child {
              color: $white !important;
              font-family: "Montserrat", sans-serif !important;
            }
          }
          td {
            border-bottom: 1px solid #404040 !important;
            font-family: "Montserrat", sans-serif !important;
            color: $white !important;
            font-size: 13px !important;
            border-right: 1px solid #404040 !important;
            &.starmatch{
              color: $secondary !important;
            }
            @media (max-width:767px){
              font-size: 12px !important;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
.starmatch {

  opacity: 1;
  color: #2d2d2d !important;
  font-weight: bold !important;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: url(../../../public/static/images/white_star.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    top: 29%;
    @media (max-width:767px){
     font-size: 16px;
     width: 16px;
     height: 16px;
    }
  }
}

@media (max-width:767px){
  .pridimain h2.hdng{
    text-align: center;
    font-size: 16px;
  }
}