@import "../../assets/theme/variable.scss";
@mixin d_flx($align: center, $justy: flex-start, $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justy;
  gap: $gap;
  flex-wrap: wrap;
}
.ldrbrd_dtls {
  position: relative;
  z-index: 3;
  .txt_inpt {
    margin: 10px 0 0;
    width: 100%;
    @media (max-width: 767px) {
      > div {
        padding: 0;
      }
    }
  }
  .tms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ffd05829;
    padding: 13px 35px 13px 15px;
    margin: 12px 0;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: 767px) {
      padding: 10px 15px;
    }

    .tms_lft {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      figure {
        height: 50px;
        width: 30px;
        margin-right: 20px;
        @media (max-width: 767px) {
          width: 33px;
          height: 42px;
          margin-right: 13px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .txt {
        color: #fff;
        font-weight: 600;
        font-size: 17px;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    .yellow_text {
      color: $secondary;
      font-weight: 600;
      font-size: 18px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}
.ldrbrd_wrp {
  position: relative;
  padding-bottom: 60px;
  &::after {
    content: "";
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    background: url("../../../public/static/img/profile_bfr_img.png") no-repeat
      top center;
    background-size: contain;
    pointer-events: none;
    min-height: 730px;
    // min-height: 300px;
    width: 830px;
    z-index: 0;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 30px;
    min-height: 100vh;
  }
}
.ldrbrd_dtls {
  > h2 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    @media (max-width: 767px) {
      margin: 0;
      text-align: center;
      font-size: 16px;
    }
  }
  .srch_field input {
    background-image: url("../../../public/static/images/search.svg");
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 24px;
    border-radius: 30px;
    padding: 14px 25px;
    @media (max-width: 767px) {
      padding: 9px 20px;
      background-position: 95% 49%;
      background-size: 20px;
    }
  }
}
.ldrbrd_wrp {
  background-image: url("../../../public/static/img/auth_bg.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  background-position: top center;
}
.ldrbrd_wrp::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #1a1a1adb 0%, #1a1a1af0 100%) 0% 0%
    no-repeat padding-box;
  pointer-events: none;
  z-index: 0;
}
.ldrbrd {
  position: relative;
  z-index: 3;
}

.filter_wrp {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin: 25px 0 25px;
}
.ldrbrd_dtls {
  .tabs {
    @include d_flx(center, space-between);
    position: relative;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
  .btn {
    padding: 11px;
    min-width: 210px;
    font-size: 16px;
    &.btn_outline {
      padding: 11px;
      background: transparent !important;
      border: 1px solid $white !important;
      color: $white !important;
    }
    @media (max-width: 767px) {
      min-width: 138px;
      font-size: 12px;
    }
  }
  .tbs_lft {
    @include d_flx(stretch, flex-start, 10px);
    @media (max-width: 767px) {
      margin: 25px 0 15px;
      justify-content: center;
    }
  }
  .tab_ryt {
    @include d_flx(center, space-between, 10px);
    @media (max-width: 767px) {
      position: absolute;
      top: -23px;
      right: 0;
    }

    p {
      font-size: 20px;
      color: $secondary;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
.ldrbrd {
  .tourmain {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
    .trRyt {
      width: calc(69% - 25px);

      @media (max-width: 767px) {
        width: 100%;
        display: block;
      }
    }
    .fltr_sec {
      width: calc(31% - 25px);
    }
  }
  .ldr_brd_prsn {
    h4 {
      font-size: 19px;
      letter-spacing: -0.19px;
      color: $white;
      font-weight: 700;
      @media (max-width: 767px) {
        font-size: 12px;
        letter-spacing: -0.12px;
      }
    }
    p {
      font-size: 14px;
      color: $white;
      font-weight: 300;
      margin-top: 8px;
      @media (max-width: 767px) {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
