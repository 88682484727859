@import "../../assets/theme/variable.scss";

@mixin d_flx($align: "center", $justify: "flex-start", $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  gap: $gap;
  flex-wrap: wrap;
}

.profilemain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding-bottom: 50px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  &::before {
    content: "";
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;

    background: url("../../../public/static/img/profile_bfr_img.png") no-repeat
      top center;
    pointer-events: none;
    min-height: 830px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .proleft {
    width: 30%;
    box-sizing: border-box;
    padding-right: 30px;
    position: sticky;
    top: 80px;
    border-right: 1px solid #404040;
    @media (max-width: 991px) {
      width: 100%;
      border-right: 0;
      position: relative;
      top: unset;
    }
    @media (max-width: 767px) {
      padding: 0;
      .hdng {
        display: none;
      }
    }
    .sidemain {
      .userinfo {
        display: flex;
        padding-top: 30px;
        .uinleft {
          figure {
            width: 80px;
            height: 80px;
            padding: 6px;
            border-radius: 80px;
            border: 1px solid #fff;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            @media (max-width: 1200px) {
              width: 50px;
              height: 50px;
            }
            @media (max-width: 767px) {
              width: 80px;
              height: 80px;
            }
          }
        }
        .uinRyt {
          padding-left: 20px;
          @media (max-width: 1200px) {
            padding-left: 10px;
          }
          @media (max-width: 991px) {
            padding-left: 16px;
          }
          h2 {
            color: #ecac35;
            font-size: 21px;
            font-weight: 500;
            text-transform: capitalize;
            @media (max-width: 1200px) {
              font-size: 16px;
            }
            @media (max-width: 767px) {
              font-size: 20px;
            }
          }
          h1 {
            font-size: 25px;
            color: white;
            padding: 9px 0;
            span {
              font-size: 15px;
              color: white;
            }
          }
          .teamsupport {
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            figure {
              width: 30px;
              height: 30px;
            }
            h5 {
              font-size: 15px;
              color: $secondary;
              font-weight: 500;
              padding-left: 10px;
              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
          .btn_sec {
            display: flex;
            justify-content: flex-start;
            button {
              font-size: 13px;
              padding: 10px 16px;
              cursor: pointer;
              outline: none;
              border: none;
              font-weight: 700;
              border-radius: 30px;
              margin-right: 8px;
              &:focus {
                background-color: $secondary !important;
              }
              @media (max-width: 767px) {
                padding: 6px 20px;
                min-width: 85px;
                background: #1d1d1d;
                color: $secondary;
                // margin-top: 15px;
              }
            }
          }
        }
        .for_mob_prfl_ttl {
          display: none;
          @media (max-width: 767px) {
            display: block;
            width: 100%;
            margin-bottom: 30px;
            h2 {
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              text-align: center;
            }
          }
        }
      }
      .winrate {
        display: flex;
        padding: 8px 0;
        border-top: 1px dashed #404040;
        border-bottom: 1px dashed #404040;
        margin: 20px 0;
        justify-content: space-around;
        @media (max-width: 767px) {
          padding: 20px 0;
          margin: 50px 0;
        }

        .win_image {
          height: 50px;
          width: 50px;
          position: relative;
          .coin_text {
            position: absolute;
            top: 14px;
            left: 16px;
            color: black;
            font-size: 18px;
            font-weight: 600;
            z-index: 1;
          }
        }
      }
      .tabs {
        padding: 14px 0;
        cursor: pointer;
        border-bottom: 3px solid transparent;

        h4 {
          font-size: 16px;
          color: $secondary;
          font-weight: 500;
          @media (max-width: 575px) {
            font-size: 14px;
          }
        }
      }
      .tabActv {
        padding: 14px 0;
        cursor: pointer;
        color: $secondary;
        border-bottom: 3px solid transparent;

        @media (max-width: 1200px) {
          padding: 8px 0;
          border: 0;
        }
        @media (max-width: 767px) {
          padding: 0 0 35px;
        }

        &:nth-child(3) {
          border-bottom: 3px solid #404040;
          margin-bottom: 14px;
          padding-bottom: 28px;
          @media (max-width: 1200px) {
            padding-bottom: 35px;
            margin-bottom: 35px;
          }
        }
        &:last-child {
          h4 {
            color: $secondary;
          }
        }
        h4 {
          font-size: 16px;
          color: $white;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        &.tabs {
          h4 {
            color: $secondary;
          }
        }
      }
    }
  }
  .proryt {
    width: 70%;
    padding: 30px;
    box-sizing: border-box;
  }
}
.sidemain {
  h2.hdng {
    font-size: 30px;
    font-weight: 500;
  }
  .uinRyt {
    h3 {
      color: #fff;
      font-size: 25px;
      font-weight: 700;
      margin-top: 5px;
      margin-bottom: 15px;
      font-family: "Impact";
      font-weight: 400;
      cursor: pointer;
      // margin: 10px 0;
      @media (max-width: 1200px) {
        font-size: 20px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
      }
      span {
        font-size: 15px;
        font-weight: 400;
        font-family: " Bw Modelica";
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}
.editprofile .acc_Contnr .inputdiv input::placeholder {
  opacity: 0.8;
}

.bck_btn {
  display: none;
}
@media (max-width: 767px) {
  .bck_btn {
    display: block;
    margin-bottom: -15px;
    width: 20px;
    height: 19px;
    color: #fff;
    transform: rotate(-90deg);
    transform-origin: center;
    &.bck_btn_prfl {
      position: absolute;
      top: 27px;
    }
    &.mt_19 {
      position: relative;
      top: 4px;
    }
  }

  .hdr:has(+ .tornmain .profilemain) {
    display: none;
  }
  .nv_spc:has(.profilemain) {
    padding-top: 0;
  }

  .prfl_wrp .container {
    padding: 0 16px;
    width: 100%;
  }

  body:has(.prfl_wrp) {
    background: #1a1a1ae6;
  }

  .profilemain .proryt {
    padding: 30px 16px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: #303030;
    z-index: 9;
    display: none;
    .body:has(.proryt_mob) {
      overflow: hidden;
    }
  }

  .tornmain.prfl_wrp .userinfo {
    background-image: url("../../../public/static/img/prfl_mob_img.svg");
    background-position: bottom center;
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 30px 16px 30px;
    flex-wrap: wrap;
  }
  .prfl_wrp + .ftr {
    display: none;
  }
  .prfl_wrp {
    padding-bottom: 30px;
  }
  .profilemain .proryt {
    width: 100%;
    padding: 30px 16px 40px;
  }
  .profilemain .proryt_mob {
    display: block;
    padding-bottom: 40px;
  }
}
.profilemain {
  align-items: stretch;
  .proryt {
    display: flex;
    flex-direction: column;
  }
}
.pagination {
  margin-top: auto;
}
