@import "../../assets/theme/variable.scss";

@mixin img_contain($contain) {
  width: 100%;
  height: 100%;
  object-fit: $contain;
}

.yellow_txt {
  color: $secondary !important;
}

.ldrbrd {
  .hdng {
    font-size: 28px;
    color: white;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 16px;
      font-weight: 500;
      text-align: center;

      .fil_sec_mob {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        gap: 6px;

        .fltr_wrp_mob {
          position: relative;
        }
      }
    }
  }

  .tm_pnts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0 30px;
    color: #fff;
    position: relative;
    cursor: pointer;

    @media (max-width: 991px) {
      padding: 30px 0 20px;
    }

    @media (max-width: 767px) {
      padding: 10px 15px;
      border: 1px solid #ffd05829;
      border-radius: 4px;
      margin: 30px 0;
    }

    span {
      height: 2px;
      width: 100%;
      border-bottom: 1px dashed rgba($white, 0.5);
      margin: 0 50px;

      @media (max-width: 1200px) {
        margin: 0 20px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .pnts_lft {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      figure {
        width: 40px;

        @media (max-width: 767px) {
          width: 32px;
        }

        img {
          object-fit: contain;
          width: 100%;
        }
      }

      .tm_nm {
        padding-left: 20px;
        font-size: 30px;
        color: #fff;
        font-weight: 600;
        white-space: nowrap;

        @media (max-width: 1200px) {
          font-size: 26px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          padding-left: 12px;
        }
      }
    }

    .pnts {
      font-size: 40px;
      font-weight: 600;
      white-space: nowrap;

      @media (max-width: 1200px) {
        font-size: 30px;
      }

      @media (max-width: 991px) {
        font-size: 26px;
      }
    }
  }

  .mn_ldrbrd {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .fltr_sec {
      width: 31%;
      border-right: 2px solid #707070;
      padding: 0px 20px 0 0;
      position: sticky;
      top: 60px;

      @media (max-width: 1200px) {
        padding-right: 15px;
      }

      @media (max-width: 767px) {
        padding: 0;
        width: 100%;
        position: relative;
        top: unset;
        display: none;
        border: 0;
      }

      .fltr_hdngs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0 24px;
        @media (max-width: 767px) {
          justify-content: center;
          position: relative;
          padding-bottom: 35px;
        }
      }

      .fltr_hdng {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;

        @media (max-width: 991px) {
          font-size: 14px;
        }
        @media (max-width: 767px) {
          color: #fff;
          display: inline-block;
          padding-top: 20px;
          border-top: 4px solid #4b4b4b;
          &::before {
          }
        }
        &:last-child {
          @media (max-width: 767px) {
            position: absolute;
            right: 0;
            color: $secondary;
            border: 0;
            font-weight: 400;
          }
        }
      }

      .ctgry_fltr {
        border-bottom: 2px solid #707070;
        padding-bottom: 14px;
        &:last-child {
          border-bottom: 0;
        }
        @media (max-width: 767px) {
          border: 0;
        }
      }

      .trny_fltr {
        border-bottom: 2px solid #707070;
        padding-bottom: 14px;
      }

      .fltr_nm {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        padding: 30px 0 20px 0;

        @media (max-width: 991px) {
          padding: 15px 0;
        }
      }

      .selct {
        li {
          padding: 16px 0;
          cursor: pointer;

          @media (max-width: 991px) {
            padding: 12px 0;
          }
          @media (max-width: 767px) {
            padding: 0 0 25px;
          }

          h4 {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }

        .activecate {
          padding: 16px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          cursor: pointer;

          @media (max-width: 1200px) {
            padding-right: 25px;
          }

          @media (max-width: 991px) {
            padding: 0 0 25px;
          }
          // @media (max-width: 991px) {
          //   padding: 0 25px 45px 0;
          // }

          h4 {
            color: $secondary;
            font-size: 16px;
            font-weight: 500;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }

          figure {
            height: 20px;
            width: 20px;
            position: absolute;
            right: 0;
          }
        }
      }

      .da_tes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        .dt_inpts {
          width: 30%;
          height: 40px;
          background: #404040;
          border: none;
          outline: none;
          text-align: center;
          color: white;

          &::placeholder {
            text-align: center;
          }
        }
      }

      button {
        // width: 100%;
        // padding: 19px 20px;
        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }

    .brd_lft {
      padding: 10px;
      width: 48%;
      margin: 0 auto;

      @media (max-width: 1200px) {
        width: 55%;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 10px 0 0;
      }

      .rank_imgs {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;

        @media (max-width: 767px) {
          padding-top: 20px;
        }

        .ranks {
          @media (max-width: 767px) {
            .ranks {
              width: 33.33%;
            }
          }

          figure {
            width: 130px;
            height: 130px;

            @media (max-width: 991px) {
              width: 100px;
              height: 100px;
            }

            @media (max-width: 767px) {
              width: 80px;
              max-width: 213px;
              height: 80px;

              img {
                height: auto;
                object-position: bottom;
              }
            }
            @media (max-width: 380px) {
              width: 60px;
              height: 60px;
            }

            img {
              @include img_contain(cover);
              border: 1px solid #ffffff4a;
              border-radius: 50%;
            }
          }

          p {
            color: #fff;
            text-align: center;
            padding-top: 16px;
            font-size: 16px;
            font-weight: 500;

            @media (max-width: 767px) {
              padding-top: 8px;
              font-size: 10px;
            }
          }
        }

        .img_top {
          position: relative;
          top: -50px;
          margin: 0 20px;

          @media (max-width: 767px) {
            top: -30px;
          }
        }
      }

      .yr_rnk {
        padding: 13px 30px;
        margin: 35px 0;
        width: 100%;
        background: #034c3a;
        border: 1px solid #c8a767;
        border-radius: 33px;

        @media (max-width: 767px) {
          margin: 24px 0;
          padding: 7px 20px;
        }

        p {
          color: #fff;
          font-size: 18px;

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }

      .userlisting {
        padding-top: 10px;

        @media (max-width: 767px) {
          padding: 0;
        }

        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            font-size: 18px;
            font-weight: 300;
            color: #fff;

            @media (max-width: 767px) {
              font-size: 12px;
            }
          }
        }

        .users {
          padding-top: 10px;

          .user {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ffffff1f;
            padding: 20px 0;

            @media (max-width: 991px) {
              padding: 12px 0;
            }

            &:last-child {
              border-bottom: none;
            }

            .userleft {
              display: flex;
              align-items: center;

              figure {
                width: 58px;
                height: 58px;

                @media (max-width: 991px) {
                  height: 45px;
                  width: 45px;
                }

                @media (max-width: 767px) {
                  width: 36px;
                  height: 36px;
                }

                img {
                  @include img_contain(cover);
                  border: 1px solid #ffffff54;
                  border-radius: 50%;
                }
              }

              h4 {
                font-size: 19px;
                font-weight: 400;
                color: #ffffff;
                margin-left: 16px;
                letter-spacing: -0.19px;

                @media (max-width: 767px) {
                  font-size: 12px;
                }
              }
            }

            h3 {
              color: #fff;
              font-size: 19px;
              font-weight: 700;

              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
          }

          .newentry {
            font-size: 18px;
            width: 100%;
            padding: 15px;
            font-weight: 900;
            margin-top: 16px;
            cursor: pointer;
            background: $secondary;
            color: $primary;
            outline: "none";
            border: "none";
          }
        }
      }
    }
  }
  .fil_sec_mob {
    display: none;
  }
  @media (max-width: 767px) {
    .fltr_wrp_mob {
      position: relative;
    }

    .fil_sec_mob {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      gap: 6px;
      top: 2px;

      p {
        color: $secondary;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  .ldrbrd .mn_ldrbrd .fltr_sec {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #1b1b1b;
    z-index: 99;
    border-radius: 25px 25px 0 0;
    display: none;
    padding: 16px;
    min-height: 500px;

    max-height: 500px;
  }

  .ldrbrd .mn_ldrbrd .fltr_sec.active {
    display: block;
    width: 100%;
  }

  .ldrbrd .mn_ldrbrd .fltr_sec .fltr_nm {
    padding: 15px 0;
    display: none;
  }
  .ldrbrd .mn_ldrbrd .fltr_sec .com {
    padding-left: 20px;
  }

  .ldrbrd .mn_ldrbrd .fltr_sec .mob-filter .fltr_nm {
    display: block;
  }

  .mob-flex {
    display: flex;
    .filter-detail {
      width: 100%;
      max-height: calc(470px - 220px);
      overflow: auto;
    }
  }

  .ldrbrd .mn_ldrbrd .fltr_sec .com {
    display: none;
  }

  .ldrbrd .mn_ldrbrd .fltr_sec .com.active {
    display: block;
  }
  .ldrbrd .mn_ldrbrd .fltr_sec {
    .mob-filter ul li.active {
      h2 {
        font-size: 14px;
        font-weight: 700;
        color: #030303;
        background: $secondary;
        padding: 15px 30px 15px 15px;
      }
    }
  }
  .mob-filter {
    ul {
      border-right: 1px solid #393939;
      li {
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 6px;
      }
    }
  }
}

@media (min-width: 768px) {
  .mob-filter {
    display: none;
  }
}
.new_filter_drwr {
  min-width: 500px;
  background-color: rgba(0, 0, 0, 0.72) !important;
  @media (max-width: 767px) {
    min-width: 100%;
    height: 100vh !important;

    overflow: hidden;
    .filter-detail {
      width: 100%;
      max-height: calc(100vh - 320px);
      padding: 0 10px;
      overflow: auto;
    }

    .mob-filter {
      display: none;
    }
  }
  .fltr_sec {
    width: 100%;
    border-right: 2px solid #707070;
    padding: 20px;
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    overflow-x: hidden;

    @media (max-width: 1200px) {
      padding-right: 15px;
    }

    @media (max-width: 767px) {
      padding: 20px;
      display: block;
      width: 100%;
      position: relative;
      top: unset;
      border: 0;
    }

    .fltr_hdngs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0 5px;
      @media (max-width: 767px) {
        // justify-content: center;
        position: relative;
        padding-bottom: 35px;
      }
    }

    .fltr_hdng {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      @media (max-width: 991px) {
        font-size: 14px;
      }
      @media (max-width: 767px) {
        color: #fff;
        display: inline-block;
        padding-top: 20px;
        border-top: 4px solid #4b4b4b;
      }
      &:last-child {
        @media (max-width: 767px) {
          position: absolute;
          right: 0;
          color: $secondary;
          border: 0;
          font-weight: 400;
        }
      }
    }

    .ctgry_fltr {
      border-bottom: 2px solid #707070;
      padding-bottom: 14px;
      &:last-child {
        border-bottom: 0;
      }
      @media (max-width: 767px) {
        border: 0;
      }
    }

    .trny_fltr {
      border-bottom: 2px solid #707070;
      padding-bottom: 14px;
    }

    .fltr_nm {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      padding: 15px 0 6px 0;

      @media (max-width: 991px) {
        padding: 15px 0;
      }
    }

    .selct {
      li {
        padding: 7px 0;
        cursor: pointer;

        @media (max-width: 991px) {
          padding: 12px 0;
        }
        @media (max-width: 767px) {
          padding: 0 0 25px;
        }

        h4 {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      .activecate {
        padding: 7px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;

        @media (max-width: 1200px) {
          padding-right: 25px;
        }

        @media (max-width: 991px) {
          // padding: 12px 25px 12px 0;
          padding: 0 0 25px;
        }

        h4 {
          color: $secondary;
          font-size: 16px;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }

        figure {
          height: 20px;
          width: 20px;
          position: absolute;
          right: 0;
        }
      }
    }

    .da_tes {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      .dt_inpts {
        width: 30%;
        height: 40px;
        background: #404040;
        border: none;
        outline: none;
        text-align: center;
        color: white;

        &::placeholder {
          text-align: center;
        }
      }
    }

    button {
      // width: 100%;
      // padding: 19px 20px;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }
  .dtpckr {
    padding: 10px;
    background: #404040;
    border: none;
    width: 100% !important;
    color: white;
  }
}
