@import "../../assets/theme/variable.scss";
@mixin d_flx($align: "center", $justify: "flex-start", $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  gap: $gap;
  flex-wrap: wrap;
}
@mixin img_contain($cover) {
  width: 100%;
  height: 100%;
  object-fit: $cover;
}

.editprofile {
  h4 {
    font-size: 16px;
    color: $secondary;
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: 767px) {
      color: #fff;
      text-align: center;
    }
  }
  .acc_Contnr {
    padding-top: 30px;
    .verified {
      border: none;
    }
    figure {
      width: 150px;
      height: 150px;
      border-radius: 150px;
      border: 1px solid #fff;
      padding: 6px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (max-width: 767px) {
        width: 100px;
        height: 100px;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
      img {
        @include img_contain(cover);
        border-radius: 50%;
      }
    }
    .vrfctn_btn2 {
      border: none;
      color: red;
      background: transparent;
      width: fit-content;
      position: absolute;
      font-weight: 500;
      cursor: pointer;
      top: 0;
      right: 15px;
      bottom: 0;
    }
    .vrfctn_btn {
      border: none;
      color: #00b959;
      background: transparent;
      width: fit-content;
      position: absolute;
      font-weight: 500;
      cursor: pointer;
      top: 0;
      right: 15px;
      bottom: 0;
    }
    .inputdiv {
      padding: 40px 50px;
      @media (max-width: 575px) {
        padding: 30px 0 0;
      }
      & > div {
        padding-right: 0 !important;
      }

      .valid {
        @include d_flx(stretch, flex-start, 10px);
        // --gap_y:10px;
        // margin-left:calc(var(--gap_y) / 2  * -1) ;
        // margin-right:calc(var(--gap_y) / 2  * -1) ;

        padding-bottom: 20px;
        width: 100%;
        p {
          color: $secondary;
          background: #daa5202b;
          padding: 10px 40px;
          cursor: pointer;
          // margin-left: calc(var(--gap_y) / 2);
          // margin-right: calc(var(--gap_y) / 2);

          border-radius: 5px;
          width: calc(33.33% - 6.66px);
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 10px;
            padding: 8px;
          }
        }
      }
    }
    .changpass {
      h5 {
        font-size: 17px;
        text-align: center;
        color: $secondary;
        font-weight: 500;
        padding-bottom: 35px;
        padding-top: 15px;
        cursor: pointer;
        @media (max-width: 767px) {
          font-size: 14px;
          padding-bottom: 80px;
          padding-top: 5px;
        }
      }
    }
    button {
      width: 100%;
      svg {
        fill: #fff;
        opacity: 0.7;
        @media (max-width: 767px) {
          margin-top: -5px;
        }
      }
      span.MuiTouchRipple-root {
        display: none;
      }
    }
    .btnn {
      margin-top: 40px;
      background: #ecac35;
      border-radius: 2px;
      padding: 11px 15px;
      color: #2d2d2d;
      font-size: 16px;
      font-weight: 700;
      // display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
    .contct {
      figure.contctfig {
        width: 340px;
        height: 290px;
        border: none !important;
        padding: 0 !important;
        @media (max-width: 767px) {
          height: 220px;
          width: 100%;
          max-width: 350px;
        }

        img {
          @include img_contain(contain);
        }
      }
      h3 {
        text-align: center;
        padding-top: 30px;
        color: white;
        font-size: 19px;
        @media (max-width: 767px) {
          padding-top: 35px;
          font-size: 16px;
          letter-spacing: -0.16px;
        }
      }
      p {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: white;
        padding-top: 10px;
        margin-bottom: 30px;
        @media (max-width: 767px) {
          margin-bottom: 16px;
          font-size: 12px;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .chatwithus {
        display: flex;
        padding: 20px 0;
        width: 90%;
        margin: auto;
        @media (max-width: 991px) {
          width: 100%;
        }
        @media (max-width: 767px) {
          padding: 15px 0;
          border-bottom: 0.5px solid #e6e6e6;
          &:first-child {
            border-top: 0.5px solid #e6e6e6;
          }
        }

        .chleft {
          figure {
            width: 30px;
            height: 25px;
            border: none !important;
            padding: 0 !important;
          }
        }
        .chRyt {
          padding-left: 20px;
          h3 {
            font-size: 14px;
            color: $secondary;
            text-align: left;
            padding-top: 0;
            cursor: pointer;
            @media (max-width: 767px) {
              font-size: 12px;
              font-weight: 700;
              color: #fff;
            }
          }
          p {
            padding-top: 6px !important;
            margin-bottom: 0px;
            font-size: 14px;
            @media (max-width: 1200px) {
              text-align: left;
              max-width: 100%;
            }
            @media (max-width: 767px) {
              font-size: 10px;
            }
          }
        }
      }
    }
    .editprofile .acc_Contnr .contct .chatwithus:not(:last-child) {
      border-bottom: 1px solid #404040;
    }
    @media (max-width: 767px) {
      .contct p + .chatwithus {
        border-top: 0.5px solid #e6e6e6;
        margin-top: 30px;
      }
    }
    .aboutpage {
      p {
        font-size: 14px;
        color: #cccccc;
        line-height: 1.7;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        @media (max-width: 991px) {
          margin-bottom: 16px;
        }
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 1.9;
        }
      }
    }
    .country {
      p {
        font-size: 15px;
        color: #b7b7b7;
        padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .seltcnty {
        .option {
          display: flex;
          padding-bottom: 25px;
          border-bottom: 1px solid #404040;
          position: relative;
          cursor: pointer;
          padding-top: 20px;
          &:last-child {
            border-bottom: 1px solid transparent;
          }
          .opleft {
            figure {
              width: 35px;
              height: 35px;
              border: none !important;
              padding: 0 !important;
            }
          }
          .opRyt {
            padding-left: 12px;
            h3 {
              font-size: 18px;
              font-weight: 500;
              color: #b7b7b7;
              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            h4 {
              font-size: 17px;
              color: $secondary;
              text-transform: capitalize;
              padding-top: 6px;
              font-weight: 600;
              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
          .selectick {
            width: 30px;
            height: 30px;
            position: absolute;
            right: 40px;
            border: none !important;
            padding: 0 !important;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .notification {
      width: 90%;
      @media (max-width: 767px) {
        width: 100%;
      }
      .noti {
        h3 {
          font-size: 15px;
          color: #b7b7b7;
          font-weight: 500;
          margin-bottom: 30px;
          padding-left: 190px;
          margin-top: 10px;
          text-transform: uppercase;
          @media (max-width: 767px) {
            margin-bottom: 16px;
            padding: 0;
            text-align: center;
          }
        }
        .notify {
          display: flex;

          border-bottom: 1px solid #404040;
          //   opacity: 0.6;

          margin-bottom: 18px;
          padding-bottom: 18px;
          @media (max-width: 767px) {
            margin-bottom: 14px;
            padding-bottom: 14px;
          }
          .notifleft {
            figure {
              width: 45px;
              height: 45px;
              border: none !important;
              padding: 0 !important;
              filter: grayscale(1);
              @media (max-width: 767px) {
                width: 35px;
                height: 35px;
              }
            }
          }
          .notifRyt {
            padding-left: 20px;
            p {
              font-size: 14px;
              color: #b7b7b7;
              font-weight: 500;
              line-height: 1.9;

              @media (max-width: 767px) {
                line-height: 1.6;
                font-size: 12px;
              }
            }
            h5 {
              font-size: 15px;
              color: #ecac35;
              font-weight: 500;
              padding-top: 5px;
              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.notification .noti:first-child {
  margin-bottom: 30px;
}
.position_relative {
  position: relative;
}
.signUpmain {
  .prfl_img_wrp {
    width: 150px;
    margin: 0 auto;
    figure.prfl_img {
      width: 100%;
      height: 150px;
      border-radius: 150px;
      border: 1px solid #fff;
      padding: 6px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: inherit;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
    }
    figure.edit_img_icon {
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      width: 45px;
      background: #fff;
      height: 45px;
      padding: 8px;
      border-radius: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
      @media (max-width: 767px) {
        height: 25px;
        width: 25px;
        padding: 4px;
        bottom: 11px;
      }
      .file_input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        cursor: pointer;
        bottom: 0;
        z-index: 9;
        text-indent: -99px;
      }
    }
  }

  @media (max-width: 767px) {
    .prdctn_drwr_auto {
      height: auto;
      overflow: auto;
    }
    .mob_vw_icon {
      figure {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
.editprofile .acc_Contnr figure.edit_img_icon {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  width: 45px;
  background: #fff;
  height: 45px;
  padding: 8px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
.about_txt {
  p {
    color: white !important;
    font-size: 14px !important;
    line-height: 26px;
  }
}
.about_txt2 {
  color: white !important;
  font-size: 14px !important;
  line-height: 26px;
  text-align: center;
}
.fa_q {
  padding-bottom: 20px;
  .faq_qtn {
    text-transform: inherit !important;
    color: #ecac35;
    @media (max-width: 767px) {
      text-align: left !important;
    }
  }

  .faq_ans {
    padding-top: 6px;
    padding-left: 6px;
  }
}
.inputdiv2 {
  padding: 0px 50px !important;
  @media (max-width: 575px) {
    padding: 0px 0 0 !important;
  }
  & > div {
    padding-right: 0 !important;
  }
}
