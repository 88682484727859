@import "../../assets/theme/variable.scss";
.bg {
  background: url("../../../public/static/images/hm_bnrimg.jpg");
  // padding-bottom: 40px;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media (max-width: 992px) {
    min-height: 500px;
  }
  @media (max-width: 767px) {
    min-height: 400px;
  }
  @media (max-width: 600px) {
    min-height: 300px;
  }
  @media (max-width: 480px) {
    min-height: 200px;
  }
  .matchmain {
    padding-left: 150px;
  }
}

.prfct_sc {
  .prfct_ctn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 22px;
    @media (max-width: 992px) {
      gap: 20px;
    }
    .prfct_inr {
      width: calc(50% - 11px);
      display: flex;
      align-items: center;
      min-height: 300px;
      padding-left: 30px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      @media (max-width: 767px) {
        padding: 15px 15px 0;
      }
    }
    .dv_lft {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right center;
      position: relative;
    }
    .top_stick {
      position: absolute;
      top: 30px;
    }
    .bottom_stick {
      position: absolute;
      bottom: 20px;
    }
    .dv_ryt {
      background: url("../../../public/static/images/perfect2.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right center;
      position: relative;
    }
    .txt {
      width: 55%;
      h2 {
        color: $white;
        font-size: 28px;
        @media (max-width: 1200px) {
          font-size: 28px;
        }
      }
      p {
        color: $white;
        font-size: 20px;
        line-height: 1.6;
        font-weight: 400;
        padding-top: 6px;
        padding-right: 17px;

        &.blk_span {
          font-size: 17px;
          color: $secondary;
          font-style: italic;
          font-weight: 400;
          + p {
            max-width: 90%;
            @media (max-width: 1200px) {
              max-width: 100%;
            }
          }
          @media (max-width: 1200px) {
            font-size: 14px;
            padding-top: 4px;
          }
        }
        @media (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }
  }
}
.all_sc.vdo_sc {
  padding-top: 40px;
  .hm_vd {
    width: 100%;
    // height: 500px;
    object-fit: cover;
    padding-top: 40px;
  }
}

@media (max-width: 1300px) {
  .prfct_sc .prfct_ctn .txt h2 {
    font-size: 30px;
  }
  .prfct_sc .prfct_ctn .txt p {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .all_sc.vdo_sc {
    padding-top: 20px;
  }
}
@media (max-width: 992px) {
  .bg .matchmain {
    padding-left: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .bg {
    height: auto;
    padding-top: 100px;
    background-position: top center;
    background-size: 100%;
  }
  .bg .matchmain {
    padding: 20px 0 0px;
  }
  .prfct_sc .prfct_ctn .txt h2 {
    font-size: 22px;
  }
  .prfct_sc .prfct_ctn .txt p {
    font-size: 12px;

    padding-right: 7px;
  }
  .prfct_sc .prfct_ctn .prfct_inr {
    min-height: 210px;
  }
  .prfct_sc .prfct_ctn {
    .dv_lft,
    .dv_ryt {
      background-size: cover;
    }
  }
}
@media (max-width: 768px) {
  .prfct_sc .prfct_ctn .prfct_inr {
    width: 100%;
    min-height: 250px;
    background-position: top right !important;
  }
}
@media (max-width: 767px) {
  .all_sc.vdo_sc .hm_vd {
    // height: 200px;
    padding-top: 20px;
  }
  .hdr.mob_vw,
  .ftr.mob_vw {
    display: block;
    padding: 7px 0;
  }
}
@media (max-width: 480px) {
  .prfct_sc .prfct_ctn .prfct_inr {
    min-height: 180px;
  }
  .prfct_sc .prfct_ctn .txt h2 {
    font-size: 18px;
  }
  .prfct_sc .prfct_ctn .txt p {
    padding-top: 7px;
  }
}
.selectteam svg.MuiSelect-icon {
  right: 25px;
  opacity: 0.7;
}
.bonus_txt {
  font-family: "Montserrat Alternates", sans-serif;
}
.reg_text {
  color: red !important;
  font-size: "14px" !important;
  padding-top: "6px";
  @media (max-width: 992px) {
    font-size: 12px !important;
    padding-top: 3px;
  }
}

.home_slider {
  position: relative;
  .prevarrow_home svg {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 99;
    background-color: #1a1a1a;
    border-radius: 50%;
    color: $secondary;
    cursor: pointer;
  }
  .nextarrow_home svg {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 99;
    background-color: #1a1a1a;
    border-radius: 50%;
    color: $secondary;
    cursor: pointer;
  }
}
