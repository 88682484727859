@import "../../assets/theme/variable.scss";
.chat_bx {
  height: calc(100vh - 160px);
  overflow: auto;
}
.chat_otr {
  background: $primary;
  padding: 40px 70px;
  min-height: 100vh;
  width: 670px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 30px 20px;
    h2 {
      font-size: 16px;
    }
  }

  h3 {
    color: white;
    text-align: center;
    font-weight: 500;
  }
  p {
    text-align: center;
    color: $secondary;
    padding-top: 10px;
  }
  .invt {
    figure {
      width: 100%;
      height: 400px;
      margin: auto;
    }
    input {
      background: #2d2d2d !important;
      width: 100% !important;
      position: relative;
      border: 1px dashed #d6d6d6 !important;
      border-radius: 33px;
      color: #fff;
    }
    p {
      font-size: 13px;
      color: $secondary;
      font-weight: 500;
      padding-top: 30px;
      width: 100%;
      line-height: 1.6;
      margin: auto;
      text-align: center;
      @media (max-width: 767px) {
        width: 100%;
        padding-top: 50px;
        font-size: 11px;
        font-weight: 500;
      }
    }
    .copy_link {
      @media (max-width: 480px) {
        width: 87%;
        word-break: break-all !important;
        text-align: left !important;
        margin: 0 !important;
        font-size: 10px !important;
      }
    }
  }
  .chats {
    padding-top: 30px;
    .chtss_inr {
      // height: calc(100vh - 210px);
      margin-bottom: 20px;
      overflow-y: auto;
    }
    .incomng {
      display: flex;
      padding-bottom: 15px;
      align-items: flex-start;
      width: 80%;
    }
    .inLeft {
      figure {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      p {
        color: #c4c4c4;
        padding-top: 3px;
        text-align: center;
        opacity: 0.6;
        font-size: 12px;
      }
    }
    .inRyt {
      padding-left: 20px;
      width: 80%;
      p {
        color: #c4c4c4;
        padding-top: 0px;
        text-align: left;
        background: #4d4d4d;
        border-radius: 7px 38px 38px 38px;
        padding: 12px;
        color: #cccccc;
        box-sizing: border-box;
        width: fit-content;
        font-size: 14px;
        line-height: normal;
      }
    }

    .outgoing {
      display: flex;
      align-items: flex-start;
      width: 80%;
      padding-bottom: 15px;
      justify-content: flex-start;
      margin: 0 0 0 auto;
      .inRyt {
        padding-left: 0px;
        padding-right: 20px;
        p {
          margin: 0 0 0 auto;
          border-radius: 38px 7px 38px 38px;
          background: #9a9a9a;
          color: #030303;
          word-break: break-all;
        }
      }
    }

    .date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      h6 {
        margin-right: 6px;
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        color: #c4c4c4;
        padding-top: 3px;
        text-align: center;
        opacity: 0.6;
        font-size: 12px;
      }
      .tick {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        h6 {
          margin-right: 6px;
          font-weight: 400;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          color: #c4c4c4;
          padding-top: 3px;
          text-align: center;
          opacity: 0.6;
          font-size: 12px;
        }
      }
      figure {
        img {
          max-width: 18px;
        }
      }
    }
    .typing {
      background: #4d4d4d;
      width: 30px;
      border-radius: 7px 38px 38px 38px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 6px;
      font-weight: bold;
      color: white;
      margin-bottom: 20px;
    }
  }
  .sendBar {
    display: flex;
    align-items: flex-start;
    background: #4d4d4d;
    border-radius: 12px;
    overflow: hidden;
    padding: 5px;
    div {
      div {
        display: flex !important;
        justify-content: space-between !important;
        padding: 0;
        textarea {
          background: #4d4d4d;
          color: #fff;
          max-height: 100px;
          overflow-y: auto !important;
          width: 100%;
          padding: 9px;
        }
      }
    }
    button {
      background: transparent;
      border: none;
      margin-left: 4px;
    }

    .iconador {
      position: absolute;
      top: 30px;
      right: 21px;
    }
  }
  .userpridict {
    .userimg {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        margin-bottom: 25px;
        position: relative;
        .mob_view {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 16px;
          display: flex;
          align-items: center;
          .bck_btn {
            margin-bottom: 0;
            position: relative;
            top: -6px;
          }
        }
      }
      figure {
        width: 50px;
        height: 50px;
      }
      h3 {
        font-size: 19px;
        padding-left: 15px;
      }
    }
    .cards {
      padding-top: 10px;
      p {
        color: #cbcbcb;
        font-size: 16px;
        padding-bottom: 10px;
        text-align: center;
        padding-top: 10px;
      }
      .matchteams {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-bottom: 10px;
        background: #d7d7d7;
        padding: 10px;
        border-radius: 15px;
        width: 500px;
        overflow: hidden;
        .team1 {
          display: flex;
          align-items: center;
          width: 40%;
          figure {
            width: 40px;
            position: relative;
            z-index: 1;
            &::before {
              content: "";
              position: absolute;
              height: 100px;
              width: 70px;
              margin: 0 auto;
              background: url(../../../public/static/images/team4shadow.png);
              background-repeat: no-repeat;
              background-size: 100%;
              top: -10px;
              left: -5px;
            }
          }
          .scro {
            padding-left: 15px;
            h2 {
              font-size: 18px;
              font-weight: 600;
            }
            h3 {
              font-size: 26px;
              font-weight: 700;
              color: #030303;
            }
          }
        }
        .team2 {
          display: flex;
          justify-content: flex-end;
          figure {
            &::before {
              left: -25px;
            }
          }
          .scro {
            padding-left: 0px;
            padding-right: 15px;
          }
          left: -25px;
        }
      }
      .centerdiv {
        figure.vs {
          width: 40px;
          margin: 0 auto;
        }
        p {
          font-size: 15px;
          text-align: center;
          color: #b2964f;
        }
      }
    }
  }
  .scrbrd_otr {
    height: calc(100vh - 240px);
    overflow-y: auto;
    padding-right: 10px;
  }

  .scoreboard {
    .or_opt {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background: #ecac35;
        z-index: -1;
      }

      h4 {
        color: $secondary;
        background: #1a1a1a;
        padding: 7px 25px;
        font-weight: 500;
        font-size: 15px;
      }
    }
    .scoretable {
      margin: 30px 0;
      .matchscroe {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 17px;
          font-weight: 600;
          padding-bottom: 20px;
        }
        .colr {
          width: 20px;
          height: 20px;
          border-radius: 100%;
        }
      }
    }
  }
}
.chat_otr.usr_pri_pop {
  padding-left: 0;
  padding-right: 0;
  .userpridict {
    .cards {
      overflow-y: auto;
      height: calc(100vh - 100px);
      padding: 10px 90px 40px;
      @media (max-width: 767px) {
        height: calc(100vh - 140px);
      }
      .matchteams {
        width: 100%;
      }
    }
  }
}

.mtcdrwr_dl {
  .matchteams {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    .team1 {
      display: flex;
      align-items: center;
      figure {
        width: 40px;
        position: relative;
        z-index: 1;
      }
      .scro {
        padding-left: 15px;
        h2 {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
        }
        h3 {
          font-size: 26px;
          color: #fff;
          font-weight: 400;
          font-family: "Impact";
        }
      }
    }
    .team2 {
      display: flex;
      .scro {
        padding-left: 0px;
        padding-right: 15px;
      }
      left: -25px;
    }
    .centerdiv {
      figure.vs {
        width: 40px;
        margin: 0 auto;
      }
      p {
        font-size: 15px;
        text-align: center;
        color: #b2964f;
      }
    }
    .scrbrd_otr {
      height: calc(100vh - 250px);
      overflow-y: auto;
      padding-right: 10px;
    }
  }
}

@media (max-width: 767px) {
  .pr_3 {
    i {
      position: relative;
      top: 3px;
    }
  }
  .chat_otr {
    width: 100%;
  }
  .chat_otr .chats .incomng {
    width: 100%;
  }
  .chat_otr .chats .outgoing {
    width: 100%;
  }
  .chat_otr .chats .inLeft figure {
    width: 40px;
    height: 40px;
  }
  .chat_otr .chats .inRyt p {
    font-size: 12px;
  }

  .mtcdrwr_dl .matchteams .team1 .scro {
    padding-left: 10px;
  }
  .mtcdrwr_dl .matchteams .team1 .scro h2 {
    font-size: 14px;
  }
  .mtcdrwr_dl .matchteams .team1 .scro h3 {
    font-size: 18px;
  }
  .mtcdrwr_dl .matchteams .team2 .scro {
    padding-right: 10px;
  }
  .mtcdrwr_dl .matchteams .centerdiv figure.vs {
    width: 30px;
  }
  .chat_otr.usr_pri_pop .userpridict .cards {
    padding: 10px 15px 10px;
  }
  .chat_otr .userpridict .cards .matchteams .team1 .scro h3 {
    font-size: 18px;
  }
  .chat_otr .userpridict .cards .matchteams .team1 .scro h2 {
    font-size: 15px;
  }
  .chat_otr .userpridict .cards .matchteams .team1 figure {
    width: 30px;
  }
  .chat_otr .userpridict .cards .matchteams .team1 {
    width: 40%;
  }
  .chat_otr .userpridict .cards .matchteams .team2 {
    justify-content: flex-end;
  }
  .chat_otr .userpridict .cards .centerdiv figure.vs {
    width: 30px;
  }
  .chat_otr .userpridict .cards .centerdiv p {
    font-size: 14px;
  }
}
