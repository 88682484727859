.tokens_received {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  @media (max-width: 767px) {
    padding-top: 20px;
  }
}
.tkn_lft {
  .txt {
    color: white;
    font-size: 14px;
    font-weight: 500;
  }
  .text_green {
    font-size: 14px;
    font-weight: 500;
    color: green;
  }
  .text_red {
    font-size: 14px;
    font-weight: 500;
    color: red;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    padding-top: 4px;
    color: #9b9b9b;
  }
}
